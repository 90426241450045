import './CustomLoader.scss';
const CustomLoader = ({ style, ...props }) => {
  return (
    <div style={style} {...props} className="custom-loader-comp">
      <div className="common-loader">
        <svg className="circular" viewBox="25 25 50 50">
          <circle
            className="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    </div>
  );
};

export default CustomLoader;
