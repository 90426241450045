export const packageFeaturesValues = {
  first: 'صغير'
};

export const getPackageFeaturesArr = (t) => [
  {
    id: 1,
    name: packageFeaturesValues.first,
    value: packageFeaturesValues.first
  }
];
