import React from 'react';
import SigninForm from './SigninForm';
// import extraLogo from '../../assets/imgs/signin/extra-logo.png';
import './SigninPage.scss';
import Logo from '../../common/logo/Logo';
import bgImg from '../../assets/imgs/BG.png';

const SigninPage = () => {
  return (
    <div className="login-page">
      <div className="page-wrapper">
        <div className="form-top-level-wrapper">
          <SigninForm />
        </div>
        <div className="img-wrap">
          <div className="custom-container">
            <Logo isExternalLink={true} className="extra-logo-img" />
          </div>
          {/* <div
            className="img-mask"
            style={{
              WebkitMaskImage: `url(${signinImg})`,
              maskImage: `url(${signinImg})`
            }}
          ></div> */}
          <img className="main-img" src={bgImg} alt="bg" />
        </div>
      </div>
    </div>
  );
};

export default SigninPage;
