import React from 'react';

const UsersSettingsIcon = ({ color, width, height, ...props }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2128 15.4933C12.1728 15.4933 12.1461 15.4933 12.1061 15.4933C12.0395 15.48 11.9461 15.48 11.8661 15.4933C7.99947 15.3733 5.07947 12.3333 5.07947 8.58663C5.07947 4.77329 8.18614 1.66663 11.9995 1.66663C15.8128 1.66663 18.9195 4.77329 18.9195 8.58663C18.9061 12.3333 15.9728 15.3733 12.2528 15.4933C12.2395 15.4933 12.2261 15.4933 12.2128 15.4933ZM11.9995 3.66663C9.2928 3.66663 7.07947 5.87996 7.07947 8.58663C7.07947 11.2533 9.15947 13.4 11.8128 13.4933C11.8928 13.48 12.0661 13.48 12.2395 13.4933C14.8528 13.3733 16.9061 11.2266 16.9195 8.58663C16.9195 5.87996 14.7061 3.66663 11.9995 3.66663Z"
        fill={color || '#404041'}
      />
      <path
        d="M22.0528 15.6667C22.0128 15.6667 21.9728 15.6667 21.9328 15.6534C21.3862 15.7067 20.8262 15.32 20.7728 14.7734C20.7195 14.2267 21.0528 13.7334 21.5995 13.6667C21.7595 13.6534 21.9328 13.6534 22.0795 13.6534C24.0262 13.5467 25.5462 11.9467 25.5462 9.98671C25.5462 7.96004 23.9062 6.32004 21.8795 6.32004C21.3328 6.33337 20.8795 5.88004 20.8795 5.33337C20.8795 4.78671 21.3328 4.33337 21.8795 4.33337C24.9995 4.33337 27.5462 6.88004 27.5462 10C27.5462 13.0667 25.1462 15.5467 22.0928 15.6667C22.0795 15.6667 22.0662 15.6667 22.0528 15.6667Z"
        fill={color || '#404041'}
      />
      <path
        d="M12.2261 30.0667C9.61281 30.0667 6.98615 29.4 4.99948 28.0667C3.14615 26.84 2.13281 25.16 2.13281 23.3334C2.13281 21.5067 3.14615 19.8134 4.99948 18.5734C8.99948 15.92 15.4795 15.92 19.4528 18.5734C21.2928 19.8 22.3195 21.48 22.3195 23.3067C22.3195 25.1334 21.3061 26.8267 19.4528 28.0667C17.4528 29.4 14.8395 30.0667 12.2261 30.0667ZM6.10615 20.2534C4.82615 21.1067 4.13281 22.2 4.13281 23.3467C4.13281 24.48 4.83948 25.5734 6.10615 26.4134C9.42615 28.64 15.0261 28.64 18.3461 26.4134C19.6261 25.56 20.3195 24.4667 20.3195 23.32C20.3195 22.1867 19.6128 21.0934 18.3461 20.2534C15.0261 18.04 9.42615 18.04 6.10615 20.2534Z"
        fill={color || '#404041'}
      />
      <path
        d="M24.4528 27.6667C23.9862 27.6667 23.5728 27.3467 23.4795 26.8667C23.3728 26.32 23.7195 25.8 24.2528 25.68C25.0928 25.5067 25.8662 25.1734 26.4662 24.7067C27.2262 24.1334 27.6395 23.4134 27.6395 22.6534C27.6395 21.8934 27.2262 21.1734 26.4795 20.6134C25.8928 20.16 25.1595 19.84 24.2928 19.64C23.7595 19.52 23.4128 18.9867 23.5328 18.44C23.6528 17.9067 24.1862 17.56 24.7328 17.68C25.8795 17.9334 26.8795 18.3867 27.6928 19.0134C28.9328 19.9467 29.6395 21.2667 29.6395 22.6534C29.6395 24.04 28.9195 25.36 27.6795 26.3067C26.8528 26.9467 25.8128 27.4134 24.6662 27.64C24.5862 27.6667 24.5195 27.6667 24.4528 27.6667Z"
        fill={color || '#404041'}
      />
    </svg>
  );
};

export default UsersSettingsIcon;
