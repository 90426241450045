const accountTypes = () => {
  return {
    superAdmin: 1,
    user: 1,
    merchent: 2,
    employee: 3
  };
};

export default accountTypes;
