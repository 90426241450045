/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import UserContext from '../contexts/user-context/UserProvider';
import { useNavigate } from 'react-router-dom';
import useCustomApiRequest from './useCustomApiRequest';
import errorNotification from '../utils/errorNotification';
import { useTranslation } from 'react-i18next';
import signinApi from '../apis/auth/signinApi';
// import successNotification from '../utils/successNotification';

const useSigninEmailPassword = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { setCurrentUser, setUserToState } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();
  const [isLoadingSignin, setIsLoadingSignin] = React.useState(false);
  const signMeInWithEmailPassword = (data) => {
    setIsLoadingSignin(true);
    customApiRequest(
      signinApi(
        {
          ...data
        },
        i18n.language
      ),
      (res) => {
        setIsLoadingSignin(false);
        if (res?.data?.status === 1 || res?.data?.status === 0) {
          // successNotification({
          //   title: t('successTitle'),
          //   message: 'تم تسجيل دخولك بنجاح'
          // });

          if (data.remember) {
            setCurrentUser({
              ...res?.data?.data,
              accountType: data?.accountType || res.data.data.type,
              activated: true
            });
            // navigate(routerLinks?.homePage);
          } else {
            setUserToState({
              ...res.data.data,
              accountType: data?.accountType || res.data.data.type,
              activated: true
            });
            // if (
            //   data?.accountType == accountTypes()?.user ||
            //   res?.data?.data?.type == accountTypes()?.user
            // ) {
            //   navigate(routerLinks?.homePage);
            // } else {
            //   navigate(merchentRouterLinks?.merchentDashboard);
            // }
          }
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
          });
        }
      },

      (error) => {
        setIsLoadingSignin(false);
        errorNotification({
          title: 'حدث خطأ اثناء تسجيل الدخول',
          message: error?.response?.data?.message || 'من فضلك حاول وقت لاحق'
        });
      }
    );
  };

  return { signMeInWithEmailPassword, isLoadingSignin, setIsLoadingSignin };
};

export default useSigninEmailPassword;
