/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import slugify from 'slugify';
import Logo from '../../common/logo/Logo';
import SignoutIcon from '../../common/icons/SignoutIcon';
import MainAppBarContext from '../../contexts/main-app-bar-context/MainAppBarProvider';
import MainAppBarSideMenu from './MainAppBarSubMenu';
import useSignout from '../../custom-hooks/useSignout';
import { LoadingOutlined } from '@ant-design/icons';
import './MainAppBar.scss';

const MainAppBar = () => {
  const { pathname } = useLocation();
  const { links, setLinks, setSelectedLink, activeLink } =
    useContext(MainAppBarContext);
  const handleMenuBtnClick = (id) => {
    setLinks((prevState) => {
      return prevState.map((menu) =>
        menu.id === id
          ? { ...menu, visibleSubMenu: true }
          : { ...menu, visibleSubMenu: false }
      );
    });

    const foundLink = links.find((link) => link.id === id);
    if (foundLink) setSelectedLink({ ...foundLink, visibleSubMenu: true });
  };

  const ref = useRef();
  const handleClickOutsideMenu = (event) => {
    if (ref && ref.current && ref.current.contains(event.target)) {
      // inside
      // to be added later
    } else {
      // outside
      event.stopPropagation();
      setLinks((prevState) => {
        return prevState.map((obj) => ({ ...obj, visibleSubMenu: false }));
      });
      setSelectedLink(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideMenu);
    document.addEventListener('keyup', handleClickOutsideMenu);

    return () => {
      document.removeEventListener('click', handleClickOutsideMenu);
      document.removeEventListener('keyup', handleClickOutsideMenu);
    };
  }, []);

  const handleActiveLink = (to, visibleSubMenu) => {
    let isActive = false;
    if (pathname && to) {
      if (pathname === to) {
        isActive = true;
      } else if (visibleSubMenu) {
        isActive = true;
      } else if (activeLink && to && slugify(activeLink?.to) == slugify(to)) {
        isActive = true;
      }
    }
    return isActive;
  };

  const renderMainLinks = () => {
    return (
      <ul className="app-bar-ul">
        {links.map((item) => {
          const {
            id,
            isLink,
            to,
            name,
            icon,
            visibleSubMenu,
            subMenu,
            isSeparator
          } = item;
          return isLink ? (
            <RouterLink
              to={to}
              className="link-li"
              key={id}
              onClick={() => handleMenuBtnClick(id)}
            >
              <div
                className={`nav-link ${
                  handleActiveLink(to, visibleSubMenu) ? 'active-link' : ''
                }`}
              >
                {icon && typeof icon === 'function' && (
                  <span
                    className="icon-span"
                    style={{
                      display: 'flex'
                    }}
                  >
                    {icon(
                      handleActiveLink(to, visibleSubMenu) ? '#2a9d7c' : ''
                    )}
                  </span>
                )}
                <span className="name-span">{name}</span>
              </div>
              <MainAppBarSideMenu
                visibleSubMenu={visibleSubMenu}
                name={id}
                menuItems={subMenu}
              />
            </RouterLink>
          ) : (
            <li
              className="link-li"
              key={id}
              onClick={() => handleMenuBtnClick(id)}
            >
              <div
                className={`nav-link
                ${slugify(pathname) === slugify(to) ? 'active-link' : ''}
                ${visibleSubMenu ? 'active-link' : ''}
                ${
                  activeLink && slugify(activeLink?.to) === slugify(to)
                    ? 'active-link'
                    : ''
                }
                `}
                // to={path}
              >
                {icon && typeof icon === 'function' && (
                  <span
                    className="icon-span"
                    style={{
                      display: 'flex'
                    }}
                  >
                    {icon(
                      slugify(pathname) === slugify(to) ||
                        visibleSubMenu ||
                        (activeLink && slugify(activeLink?.to) === slugify(to))
                        ? '#fff'
                        : ''
                    )}
                  </span>
                )}
                <span
                  className={isSeparator ? 'name-span separator' : 'name-span'}
                >
                  {name}
                </span>
              </div>
              {subMenu?.length > 0 && (
                <MainAppBarSideMenu
                  visibleSubMenu={visibleSubMenu}
                  name={id}
                  menuItems={subMenu}
                />
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const { signMeOut, isLoadingSignout } = useSignout();
  const handleSignout = () => {
    signMeOut();
  };

  return (
    <div className="main-app-bar-wrap" ref={ref}>
      <Logo className="side-menu-logo" />

      {renderMainLinks()}

      <div className="settings-signout-wrap" onClick={handleSignout}>
        {isLoadingSignout ? (
          <LoadingOutlined style={{ color: '#b5b5b5', fontSize: 24 }} />
        ) : (
          <SignoutIcon />
        )}
      </div>
    </div>
  );
};

export default MainAppBar;
