/* eslint-disable eqeqeq */

import CustomImage from '../../common/custom-image/CustomImage';
import EditIcon from '../../common/icons/EditIcon';
import TrashIcon from '../../common/icons/TrashIcon';
import medalIcon from '../../assets/imgs/icons/packages-icons/medal.svg';
import posIcon from '../../assets/imgs/icons/packages-icons/pos-icon.svg';
import ActivateIcon from '../../common/icons/ActivateIcon';
import DeactivateIcon from '../../common/icons/DeactivateIcon';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes';
import { packagesStatusValues } from '../../const-values/packagesStatus';
import CommonTag from '../../common/common-tag/CommonTag';

const AppPackagesTableColumns = ({
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  setConfirmModal,
  setDeleteModalOpened,
  i18n,
  t,
  ...props
}) => {
  const handleClickConfirm = (record, type) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setConfirmModal({
        opened: true,
        type
      });
    }
  };
  const handleEdit = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setFormModalOpened(true);
    }
  };
  const handleDeletePackage = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setDeleteModalOpened(true);
    }
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap two-rows">
        <div className="main-btns-wrapper">
          <button
            className="action-btn edit"
            onClick={() => handleDeletePackage(record)}
          >
            <TrashIcon color="#196c10" />
          </button>
          <button
            className="action-btn edit"
            onClick={() => handleEdit(record)}
          >
            <EditIcon color="#196c10" />
          </button>
        </div>

        <div className="accept-reject-btns">
          {record?.status == packagesStatusValues?.notActive ? (
            <button
              className="accept-btn"
              onClick={() => {
                handleClickConfirm(
                  record,
                  confirmModalTypesValues?.successModal
                );
              }}
            >
              <ActivateIcon />
              تفعيل
            </button>
          ) : null}
          {record?.status == packagesStatusValues?.active ? (
            <button
              className="deactivate-btn"
              onClick={() =>
                handleClickConfirm(record, confirmModalTypesValues?.errorModal)
              }
            >
              <DeactivateIcon />
              إلغاء التفعيل
            </button>
          ) : null}
        </div>
      </div>
    );
  };

  // const SingleTag = (item) => {
  //   if (item?.pacakge?.name) {
  //     return ()
  //   }
  // }

  // const renderSubscriptionTags = arr => {
  //   return (
  //     arr.map(item => {
  //       return (
  //         <SingleTag key={item?.id} item={item} />
  //       )
  //     })
  //   )
  // }

  const RenderTag = ({ iconSrc, title, className }) => {
    return <CommonTag title={title} iconSrc={iconSrc} className={className} />;
  };

  return [
    {
      title: '',
      dataIndex: 'image',
      render: (_, record) => {
        return (
          <div className="table-img-wrapper">
            <CustomImage className="table-package-img" src={record?.icon} />
          </div>
        );
      }
    },
    {
      title: 'الباقة',
      dataIndex: 'name',
      render: (_, record) => {
        return record?.name || '----';
      }
    },
    {
      title: 'تفاصيل الباقة',
      dataIndex: 'tags',
      render: (_, record) => {
        return (
          <div className="package-tags">
            <div className="subscription-tags">
              {record.duration ? (
                <RenderTag
                  iconSrc={medalIcon}
                  title={`اشتراك ${record?.duration || ''}`}
                  className="yellow"
                />
              ) : null}
              <RenderTag
                iconSrc={medalIcon}
                title={`باقة ${
                  record?.price ? `${record.price} ${t('currency.sar')}` : ''
                }`}
                className="yellow"
              />
            </div>
            <div className="device-tags">
              {record.device?.name ? (
                <RenderTag
                  iconSrc={posIcon}
                  title={`${record?.device?.name} ${record?.device?.model}`}
                  className="green"
                />
              ) : null}
              {record.device?.brand?.name ? (
                <RenderTag
                  iconSrc={posIcon}
                  title={`${record.device.brand.name}`}
                  className="green"
                />
              ) : null}
            </div>
          </div>
        );
      }
    },

    {
      title: 'إجراءات',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }

    // {
    //   title: 'عرض التفاصيل',
    //   dataIndex: 'details',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <Tooltip title="عرض التفاصيل">
    //         <Button
    //           size="large"
    //           type="dashed"
    //           shape="round"
    //           icon={<EyeOutlined />}
    //           onClick={() => handleViewDelegateDetails(record)}
    //         />
    //       </Tooltip>
    //     );
    //   }
    // }
  ];
};

export default AppPackagesTableColumns;
