/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SharedAntdTable from '../../common/shared-antd-table/SharedAntdTable';
import UserContext from '../../contexts/user-context/UserProvider';
import {
  setDeleteModalOpened,
  setPostFormModalOpened,
  setSelectedTableRow
} from '../../redux/clients-slice/clientsSlice';
import RecentClientsTableColumns from './RecentClientsTableColumns';

const RecentClientsTable = () => {
  const { user } = useContext(UserContext);
  const fetchedTableData = useSelector(
    (store) => store.clients.fetchedTableData
  );
  const deleteModalOpened = useSelector(
    (store) => store.clients.deleteModalOpened
  );
  const fetchDataCount = useSelector((store) => store.clients.fetchDataCount);
  const isLoadingTableData = useSelector(
    (store) => store.clients.isLoadingTableData
  );
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  // map all data to dataSource
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };
  useEffect(() => {
    let isMounted = true;

    if (fetchedTableData?.length >= 0) {
      const mappedData = mapDataSource(fetchedTableData);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchDataCount, fetchedTableData]);
  return (
    <div className="recent-clients-table-wrapper">
      <h3
        style={{
          color: 'rgba(64, 64, 65, 0.6)',
          marginBottom: 18,
          fontWeight: 'bold'
        }}
      >
        عملاء انضمو حديثا
      </h3>

      <SharedAntdTable
        scroll={1200}
        showHeader={true}
        className={`recent-clients-table ${i18n.dir()}`}
        hasPagination={false}
        tableDataSource={tableDataSource}
        tableColumns={RecentClientsTableColumns}
        allFetchedTableData={fetchedTableData}
        setFormModalOpened={() => dispatch(setPostFormModalOpened(true))}
        // setDetailsModalOpened={setDetailsModalOpened}
        //
        deleteModalOpened={deleteModalOpened}
        setDeleteModalOpened={(v) => dispatch(setDeleteModalOpened(v))}
        setSelectedTableRow={(v) => dispatch(setSelectedTableRow(v))}
        //

        isLoadingTableData={isLoadingTableData}
        user={user}
      />
    </div>
  );
};

export default RecentClientsTable;
