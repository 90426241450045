import axios from 'axios';
import apisUrls from '../urls';

const createDeviceApi = (values, edit = false) => {
  const url = !edit ? apisUrls?.createDevice : apisUrls?.updateDevice;
  return axios.post(url, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export default createDeviceApi;
