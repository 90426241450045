import { useRef } from 'react';
import { useState } from 'react';
import { searchImgSrc, xImgSrc } from '../../const-values/imagesImports';
import './TableFilterSearchComponent.scss';

const TableFilterSearchComponent = ({
  placeholder,
  onClick,
  onSearch,
  defaultValue,
  searchText,
  setSearchText,
  ...props
}) => {
  const [formVisible, setFormVisible] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchText && onSearch && typeof onSearch === 'function') {
      onSearch();
    }
  };

  return (
    <div className="control-btn-search-form-warpper">
      <form
        className={`table-filter-search-form ${formVisible ? 'visible' : ''}`}
        onSubmit={handleSubmit}
      >
        <button type="submit">
          <img src={searchImgSrc} alt="search" />
        </button>

        <input
          placeholder={placeholder || 'إبحث هنــا ...'}
          type="text"
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          value={defaultValue || searchText || ''}
        />
      </form>

      <button
        onClick={() => {
          setFormVisible((prev) => !prev);
        }}
        className={`form-control-btn ${formVisible ? 'visible' : ''}`}
      >
        {formVisible ? (
          <img src={xImgSrc} alt="close" />
        ) : (
          <img src={searchImgSrc} alt="search" />
        )}
      </button>
    </div>
  );
};

export default TableFilterSearchComponent;
