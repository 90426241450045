export const deviceFontsSizesValues = {
  small: 'صغير',
  large: 'كبير'
};

export const getDeviceFontsSizesArr = (t) => [
  {
    id: 1,
    name: deviceFontsSizesValues.small,
    value: deviceFontsSizesValues.small
  },
  {
    id: 2,
    name: deviceFontsSizesValues.large,
    value: deviceFontsSizesValues.large
  }
];
