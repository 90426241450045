import medalImg from '../assets/imgs/icons/medal-star.svg';
import usersImg from '../assets/imgs/icons/users-img.svg';
import searchImg from '../assets/imgs/icons/serch-img.svg';
import xImg from '../assets/imgs/icons/x-img.svg';
import discountImg from '../assets/imgs/icons/form-icons/discount-icon.svg';

export const medalImgSrc = medalImg;
export const usersImgSrc = usersImg;
export const searchImgSrc = searchImg;
export const xImgSrc = xImg;
export const discountImgSrc = discountImg;
