import React from 'react';

const AppbarSettingsIcon = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99998 15.1666H5.99998C2.37998 15.1666 0.833313 13.6199 0.833313 9.99992V5.99992C0.833313 2.37992 2.37998 0.833252 5.99998 0.833252H9.99998C13.62 0.833252 15.1666 2.37992 15.1666 5.99992V9.99992C15.1666 13.6199 13.62 15.1666 9.99998 15.1666ZM5.99998 1.83325C2.92665 1.83325 1.83331 2.92659 1.83331 5.99992V9.99992C1.83331 13.0733 2.92665 14.1666 5.99998 14.1666H9.99998C13.0733 14.1666 14.1666 13.0733 14.1666 9.99992V5.99992C14.1666 2.92659 13.0733 1.83325 9.99998 1.83325H5.99998Z"
        fill={color || '#fff'}
      />
      <path
        d="M10.3867 12.8334C10.1133 12.8334 9.88666 12.6067 9.88666 12.3334V9.7334C9.88666 9.46007 10.1133 9.2334 10.3867 9.2334C10.66 9.2334 10.8867 9.46007 10.8867 9.7334V12.3334C10.8867 12.6067 10.66 12.8334 10.3867 12.8334Z"
        fill={color || '#fff'}
      />
      <path
        d="M10.3867 5.46675C10.1133 5.46675 9.88666 5.24008 9.88666 4.96675V3.66675C9.88666 3.39341 10.1133 3.16675 10.3867 3.16675C10.66 3.16675 10.8867 3.39341 10.8867 3.66675V4.96675C10.8867 5.24008 10.66 5.46675 10.3867 5.46675Z"
        fill={color || '#fff'}
      />
      <path
        d="M10.3867 8.93322C9.15332 8.93322 8.15332 7.93322 8.15332 6.69989C8.15332 5.46655 9.15332 4.46655 10.3867 4.46655C11.62 4.46655 12.62 5.46655 12.62 6.69989C12.62 7.93322 11.6133 8.93322 10.3867 8.93322ZM10.3867 5.46655C9.70665 5.46655 9.15332 6.01989 9.15332 6.69989C9.15332 7.37989 9.70665 7.93322 10.3867 7.93322C11.0667 7.93322 11.62 7.37989 11.62 6.69989C11.62 6.01989 11.06 5.46655 10.3867 5.46655Z"
        fill={color || '#fff'}
      />
      <path
        d="M5.61334 12.8334C5.34001 12.8334 5.11334 12.6068 5.11334 12.3334V11.0334C5.11334 10.7601 5.34001 10.5334 5.61334 10.5334C5.88668 10.5334 6.11334 10.7601 6.11334 11.0334V12.3334C6.11334 12.6068 5.89334 12.8334 5.61334 12.8334Z"
        fill={color || '#fff'}
      />
      <path
        d="M5.61334 6.76675C5.34001 6.76675 5.11334 6.54008 5.11334 6.26675V3.66675C5.11334 3.39341 5.34001 3.16675 5.61334 3.16675C5.88668 3.16675 6.11334 3.39341 6.11334 3.66675V6.26675C6.11334 6.54008 5.89334 6.76675 5.61334 6.76675Z"
        fill={color || '#fff'}
      />
      <path
        d="M5.61334 11.5333C4.38 11.5333 3.38 10.5333 3.38 9.29998C3.38 8.06665 4.38 7.06665 5.61334 7.06665C6.84667 7.06665 7.84667 8.06665 7.84667 9.29998C7.84667 10.5333 6.84667 11.5333 5.61334 11.5333ZM5.61334 8.06665C4.93334 8.06665 4.38 8.61998 4.38 9.29998C4.38 9.97998 4.93334 10.5333 5.61334 10.5333C6.29334 10.5333 6.84667 9.97998 6.84667 9.29998C6.84667 8.61998 6.3 8.06665 5.61334 8.06665Z"
        fill={color || '#fff'}
      />
    </svg>
  );
};

export default AppbarSettingsIcon;
