/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { getCurrentToken } from '../firebase';
const useFCMDeviceToken = () => {
  const [fcmDeviceToken, setFcmDeviceToken] = useState('');
  const [isTokenFound, setIsTokenFound] = useState(false);

  useEffect(() => {
    let isMounted = true;
    try {
      if (isMounted) {
        const tokenFunc = async () => {
          const data = await getCurrentToken(setIsTokenFound);
          if (data) {
            console.log('Token is', data);
            setFcmDeviceToken(data);
          }
          return data;
        };
        tokenFunc();
      }
    } catch (error) {
      console.log(error);
    }
    return () => {
      isMounted = false;
    };
  }, [setIsTokenFound]);

  return fcmDeviceToken;
};

export default useFCMDeviceToken;
