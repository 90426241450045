import FacebookIcon from '../../common/icons/social-icons/FacebookIcon';
import InstagramIcon from '../../common/icons/social-icons/InstagramIcon';
import YoutubeIcon from '../../common/icons/social-icons/YoutubeIcon';
import './SocialStatistics.scss';

const SocialStatistics = () => {
  const boxsArr = [
    {
      id: 1,
      title: 'حسابك فى Instagram',
      icon: <InstagramIcon />,
      url: 'https://google.com'
    },
    {
      id: 2,
      title: 'قناتك على Youtube',
      icon: <YoutubeIcon />,
      url: 'https://google.com'
    },
    {
      id: 3,
      title: 'حسابك على Facebook',
      icon: <FacebookIcon />,
      url: 'https://google.com'
    }
  ];

  const Box = ({ title, url, icon }) => {
    return (
      <a href={url} target="_blank" className="box-link" rel="noreferrer">
        <div className="box-icon">{icon}</div>
        <div className="box-text">
          <p className="title-p">{title}</p>
        </div>
      </a>
    );
  };

  return (
    <div className="h3 list-wrapper">
      <h3
        style={{
          color: '#40404199',
          marginBottom: 22,
          fontWeight: 'bold'
        }}
      >
        منصات التواصل الاجتماعى
      </h3>
      <div className="social-stats-boxs-wrapper">
        {boxsArr.map((item) => (
          <Box key={item?.id} {...item} />
        ))}
      </div>
    </div>
  );
};

export default SocialStatistics;
