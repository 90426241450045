export const devicePapersSizesValues = {
  fifty: '50/57 mm',
  eighty: '80/88 mm'
};

export const getDevicePapersSizesArr = (t) => [
  {
    id: 1,
    name: devicePapersSizesValues.fifty,
    value: devicePapersSizesValues.fifty
  },
  {
    id: 2,
    name: devicePapersSizesValues.eighty,
    value: devicePapersSizesValues.eighty
  }
];
