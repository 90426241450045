import React from 'react';

const SignoutIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.104"
      height="26.106"
      viewBox="0 0 26.104 26.106"
    >
      <path
        id="Path_843"
        data-name="Path 843"
        d="M69.985,816.515v2.04a4.084,4.084,0,0,0,4.079,4.079H84.313a4.084,4.084,0,0,0,4.079-4.079V800.607a4.084,4.084,0,0,0-4.079-4.079H74.064a4.084,4.084,0,0,0-4.079,4.079v2.04a1.02,1.02,0,0,0,2.039,0v-2.04a2.042,2.042,0,0,1,2.039-2.039H84.313a2.042,2.042,0,0,1,2.039,2.039v17.948a2.042,2.042,0,0,1-2.039,2.04H74.064a2.042,2.042,0,0,1-2.039-2.04v-2.04a1.02,1.02,0,0,0-2.039,0Zm-6.952-8.686,2.283-2.283a1.02,1.02,0,0,1,1.442,1.442l-1.624,1.624H77.378a1.02,1.02,0,0,1,0,2.04H65.134l1.624,1.624a1.02,1.02,0,1,1-1.442,1.442l-2.283-2.283A2.553,2.553,0,0,1,63.033,807.829Z"
        transform="translate(-62.287 -796.528)"
        fill={color ? color : '#b5b5b5'}
      />
    </svg>
  );
};

export default SignoutIcon;
