import ProfileInfoSection from './ProfileInfoSection';

const SettingsPage = () => {
  return (
    <div className="settings-page">
      <ProfileInfoSection />
    </div>
  );
};

export default SettingsPage;
