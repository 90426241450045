import { configureStore } from '@reduxjs/toolkit';
import archiveReducer from './archive-slice/archiveSlice';
import brandsReducer from './brands-slice/brandsSlice';
import clientsReducer from './clients-slice/clientsSlice';
import counterReducer from './counter/counterSlice';
import devicesReducer from './devices-slice/devicesSlice';
import influencersReducer from './influcencers-slice/influencersSlice';
import packagesReducer from './packages-slice/appPackagesSlice';
import settingsReducer from './settings-slice/settingsSlice';

export default configureStore({
  reducer: {
    counter: counterReducer,
    clients: clientsReducer,
    archive: archiveReducer,
    settings: settingsReducer,
    appPackages: packagesReducer,
    devices: devicesReducer,
    brands: brandsReducer,
    influencers: influencersReducer
  }
});
