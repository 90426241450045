export const subscriptionStatus = {
  notCompleted: 0,
  unActive: 1,
  active: 2
};

export const getSubsStatusArr = () => {
  return [
    {
      id: 1,
      name: 'غير مكتمل',
      value: subscriptionStatus.notCompleted
    },
    {
      id: 2,
      name: 'غير نشط',
      value: subscriptionStatus.unActive
    },
    {
      id: 3,
      name: 'نشط',
      value: subscriptionStatus.active
    }
  ];
};
