/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import './DeviceForm.scss';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useDispatch, useSelector } from 'react-redux';
import { setDeviceFormOpenedAction } from '../../redux/devices-slice/devicesSlice';
import Xicon from '../../common/icons/Xicon';
import FileInput from '../../common/file-input/FileInput';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { getDevicePapersSizesArr } from '../../const-values/devicePapersSizesValues';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import { getDeviceFontsSizesArr } from '../../const-values/deviceFontsSizesValues';
import {
  createDeviceAction,
  updateDeviceAction
} from '../../redux/devices-slice/devicesSliceThunkApis';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';

const schema = Yup.object().shape({
  name_ar: Yup.string().required('من فضلك ادخل اسم الجهاز بالعربية'),
  name_en: Yup.string().required('من فضلك ادخل اسم الجهاز بالانجليزية'),
  model: Yup.string().required('من فضلك ادخل موديل الجهاز'),
  brand_id: Yup.string().required('من فضلك اختار براند الجهاز')
});

const {
  medalIcon,
  deviceIcon,
  paperSizeIcon,
  fontSizeIcon,
  deviceDescIcon,
  imageIcon
} = imagesSrcs;

const emptyFormValues = {
  name_ar: '',
  name_en: '',
  model: '',
  brand_id: '',
  papers: [],
  details: [],
  fonts: [],
  image: null
};

const DeviceForm = () => {
  const dispatch = useDispatch();
  const selectedTableRow = useSelector(
    (store) => store.devices.selectedTableRow
  );

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  ///////////////
  // brands selectors
  const fetchedBrands = useSelector((store) => store.brands.fetchedBrands);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    unregister,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: emptyFormValues
  });

  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      setValue('name_ar', selectedTableRow?.name_ar);
      setValue('name_en', selectedTableRow?.name_en);
      setValue('model', selectedTableRow?.model || '');
      setValue(
        'brand_id',
        selectedTableRow?.brand_id ? String(selectedTableRow?.brand_id) : ''
      );
      if (selectedTableRow?.papers?.length > 0)
        setValue(
          'papers',
          selectedTableRow.papers.map((item) => item?.name)
        );
      if (selectedTableRow?.fonts?.length > 0)
        setValue(
          'fonts',
          selectedTableRow.fonts.map((item) => item?.name)
        );
      if (selectedTableRow?.details?.length > 0)
        setValue(
          'details',
          selectedTableRow.details.map((item) => item?.name)
        );
    }
  }, [selectedTableRow]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('name_ar', data.name_ar || '');
    formData.append('name_en', data.name_en || '');
    formData.append('description_ar', data?.description_ar || '');
    formData.append('description_en', data?.description_en || '');
    formData.append('model', data.model || '');
    formData.append('brand_id', data.brand_id || '');
    if (data?.papers?.length > 0) {
      for (let i of data.papers) formData.append('papers[]', i);
    }
    if (data?.fonts?.length > 0) {
      for (let i of data.fonts) formData.append('fonts[]', i);
    }
    if (data?.details?.length > 0) {
      for (let i of data.details) formData.append('details[]', i);
    }
    if (data?.image?.length > 0) {
      formData.append('image', data.image[0]);
    }
    if (!selectedTableRow) {
      dispatch(
        createDeviceAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    } else formData.append('device_id', selectedTableRow?.id || '');
    dispatch(
      updateDeviceAction({
        dataToBeSubmitted: formData,
        cb: () => {
          reset(emptyFormValues);
        }
      })
    );
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form device-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <p className="form-title">
        إضافة جهاز جديد
        <button
          type="button"
          className="back-btn"
          onClick={() => {
            dispatch(setDeviceFormOpenedAction(false));
          }}
        >
          {/* <ArrowToLeftIcon color="#40404199" /> */}
          <Xicon color="#40404199" />
        </button>
      </p>
      <div className="form-body">
        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={deviceIcon} alt="name" />
              <span>إســم الجهاز بالعربية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="name_ar"
              type="text"
              placeholder={'إســم الجهاز بالعربية...'}
              // label="الاســــم"
              errorMsg={errors?.name_ar?.message}
              validateStatus={errors?.name_ar ? 'error' : ''}
              control={control}
            />
          </div>

          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={deviceIcon} alt="name" />
              <span>إســم الجهاز بالانجليزية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="name_en"
              type="text"
              placeholder={'إســم الجهاز بالانجليزية...'}
              // label="الاســــم"
              errorMsg={errors?.name_en?.message}
              validateStatus={errors?.name_en ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={deviceIcon} alt="name" />
              <span>موديل الجهاز</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="model"
              type="text"
              placeholder={'موديل الجهاز...'}
              // label="الاســــم"
              errorMsg={errors?.model?.message}
              validateStatus={errors?.model ? 'error' : ''}
              control={control}
            />
          </div>
          <div className="select-label-wrap shared-app-select-option-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              أختار براند الجهاز
            </p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name={`brand_id`}
                errorMsg={errors?.brand_id && errors.brand_id.message}
                validateStatus={errors?.brand_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder="أختار براند الجهاز"
                options={
                  fetchedBrands?.length > 0 &&
                  fetchedBrands.map((obj) => ({
                    title: obj?.name,
                    value: obj?.id
                  }))
                }
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="device-form"
              />
            </div>
          </div>
        </div>

        <div className="custom-select-wrap without-icon shared-app-select-option-label-wrapper multiple-select-option">
          <p className="outside-label">
            <img src={fontSizeIcon} alt="name" />
            ادخل احجام ورقة الطباعة
          </p>
          <AntdSelectOption
            name={`papers`}
            errorMsg={errors?.papers && errors.papers.message}
            validateStatus={errors?.papers ? 'error' : ''}
            control={control}
            setValue={setValue}
            placeholder="أدخل احجام ورقة الطباعة"
            mode="tags"
            options={
              getDevicePapersSizesArr()?.length > 0 &&
              getDevicePapersSizesArr().map((obj) => ({
                title: obj?.name,
                value: obj?.value
              }))
            }
            showSearch={true}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            formClassName="device-form"
          />
        </div>
        {/*  */}
        <div className="custom-select-wrap without-icon shared-app-select-option-label-wrapper multiple-select-option">
          <p className="outside-label">
            <img src={deviceDescIcon} alt="name" />
            ادخل أحجام خط الطباعة
          </p>
          <AntdSelectOption
            name={`fonts`}
            errorMsg={errors?.fonts && errors.fonts.message}
            validateStatus={errors?.fonts ? 'error' : ''}
            control={control}
            setValue={setValue}
            placeholder="أدخل أحجام خط الطباعة"
            mode="tags"
            options={
              getDeviceFontsSizesArr()?.length > 0 &&
              getDeviceFontsSizesArr().map((obj) => ({
                title: obj?.name,
                value: obj?.value
              }))
            }
            showSearch={true}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            formClassName="device-form"
          />
        </div>
        {/*  */}
        <div className="custom-select-wrap without-icon shared-app-select-option-label-wrapper multiple-select-option">
          <p className="outside-label">
            <img src={paperSizeIcon} alt="name" />
            تفاصيل الجهاز
          </p>
          <AntdSelectOption
            name={`details`}
            errorMsg={errors?.details && errors.details.message}
            validateStatus={errors?.details ? 'error' : ''}
            control={control}
            setValue={setValue}
            placeholder="تفاصيل الجهاز"
            mode="tags"
            options={[
              {
                title: 'جهاز مودييل 2022',
                value: 'جهاز موديل 2022'
              }
            ]}
            showSearch={true}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            formClassName="device-form"
          />
        </div>

        {/* DESC */}
        <div className="shared-app-text-field-label-wrapper is-text-area">
          <p className="outside-label">
            <img src={deviceIcon} alt="name" />
            <span>تفاصيل الجهاز بالعربية</span>
          </p>
          <AntdTextarea
            className="form-text-field"
            rows={5}
            name="description_ar"
            type="text"
            placeholder={'تفاصيل الجهاز بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.description_ar?.message}
            validateStatus={errors?.description_ar ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper is-text-area">
          <p className="outside-label">
            <img src={deviceIcon} alt="name" />
            <span>تفاصيل الجهاز بالانجليزية</span>
          </p>
          <AntdTextarea
            className="form-text-field"
            rows={5}
            name="description_en"
            type="text"
            placeholder={'تفاصيل الجهاز بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.description_en?.message}
            validateStatus={errors?.description_en ? 'error' : ''}
            control={control}
          />
        </div>
        {/* END DESC */}

        <div className="file-inputs-wrap">
          <FileInput
            name="image"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label={
              <p
                className="outside-label"
                style={{
                  color: '#404041',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  marginBottom: 8,
                  paddingRight: 8
                }}
              >
                <img src={imageIcon} alt="name" />
                صورة الجهاز
              </p>
            }
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب الصورة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedTableRow?.image ? [{ url: selectedTableRow.image }] : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.image?.message}
          />
        </div>

        <div className="modal-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            onClick={() => {
              dispatch(setDeviceFormOpenedAction(false));
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {/* {isLoadingSignin && <LoadingModal />} */}
    </Form>
  );
};

export default DeviceForm;
