/* eslint-disable react-hooks/exhaustive-deps */
import './ClientsPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import ConfirmModal from '../../common/confirm-modal/ConfirmModal';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes';
import { AnimatePresence, motion } from 'framer-motion';
import PackageForm from './ClientForm';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { getPackagesAction } from '../../redux/packages-slice/appPackagesSliceThunkApi';
import {
  changeClientStatusAction,
  deleteClientAction,
  getClientsAction
} from '../../redux/clients-slice/clientsSliceThunkApi';
import {
  setConfirmModal,
  setDeleteModalOpened
} from '../../redux/clients-slice/clientsSlice';
import ClientsPageFilter from './ClientsPageFilter';
import ClientsPageTable from './ClientsPageTable';
import PackageDescription from './PackageDescription';

const ClientsPage = () => {
  const dispatch = useDispatch();
  const deleteModalOpened = useSelector(
    (store) => store.clients.deleteModalOpened
  );
  const opened = useSelector((store) => store.clients.confirmModal.opened);
  const type = useSelector((store) => store.clients.confirmModal.type);
  const isLoadingTableData = useSelector(
    (store) => store.clients.isLoadingTableData
  );
  const selectedTableRow = useSelector(
    (store) => store.clients.selectedTableRow
  );
  const postFormModalOpened = useSelector(
    (store) => store.clients.postFormModalOpened
  );
  const watchClientPackage = useSelector(
    (store) => store.clients.watchClientPackage
  );
  const fetchDataCount = useSelector((store) => store.clients.fetchDataCount);
  const isSubmitting = useSelector((store) => store.clients.isSubmitting);
  const { search } = useLocation();
  const values = queryString.parse(search);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let isMounted = true;
  useEffect(() => {
    if (isMounted)
      dispatch(
        getPackagesAction({
          filterObj: null
        })
      );

    return function () {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted)
      dispatch(
        getClientsAction({
          filterObj: values
        })
      );

    return function () {
      isMounted = false;
    };
  }, [fetchDataCount, search]);

  const handleDeleteClient = () => {
    if (isMounted && selectedTableRow?.id) {
      const formData = new FormData();
      formData.append('client_id', selectedTableRow.id);
      dispatch(
        deleteClientAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  const handleChangeClientStatus = () => {
    if (isMounted && selectedTableRow?.id) {
      const formData = new FormData();
      formData.append('client_id', selectedTableRow.id);
      dispatch(
        changeClientStatusAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  if (isLoadingTableData) {
    return (
      <div className="clients-page">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 360
          }}
          className="loading-wrapper"
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      </div>
    );
  }
  return (
    <div className="clients-page">
      <div
        className={`page-content-devices-wrapper ${
          postFormModalOpened ? 'with-side-content' : ''
        }`}
      >
        <div>
          <AnimatePresence>
            {!postFormModalOpened && (
              <motion.div
                // layout
                initial={{
                  width: 0,
                  height: 0,
                  opacity: 0,
                  translateX: 700,
                  transition: {
                    opacity: { ease: 'linear', duration: 0.3 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                animate={{
                  width: '100%',
                  height: 'auto',
                  opacity: 1,
                  translateX: 0,
                  transition: {
                    delay: 0.7,
                    opacity: { ease: 'linear', duration: 0.8 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                exit={{
                  width: 400,
                  height: 0,
                  opacity: 0,
                  translateX: 400,
                  transition: {
                    opacity: { ease: 'linear', duration: 0.5 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                className="page-main-content"
              >
                <div className="page-title">العملاء</div>
                <ClientsPageFilter />
                <ClientsPageTable />
              </motion.div>
            )}
          </AnimatePresence>

          {/* PACKAGE FORM */}
          <AnimatePresence>
            {postFormModalOpened && (
              <motion.div
                initial={{
                  width: 0,
                  // height: 0,
                  opacity: 0,
                  translateX: 700
                }}
                animate={{
                  width: '100%',
                  // height: 'auto',
                  opacity: 1,
                  translateX: 0,
                  transition: {
                    delay: 0.6,
                    opacity: { ease: 'linear', duration: 0.5 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                exit={{
                  width: 200,
                  // height: 0,
                  opacity: 0,
                  translateX: 400,
                  transition: {
                    opacity: { ease: 'linear', duration: 0.5 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                // transition={{
                //   delay: 0.7,
                //   opacity: { ease: 'linear', duration: 0.5 },
                //   width: { ease: 'linear', duration: 0.5 },
                //   translateX: { ease: 'linear', duration: 0.5 }
                // }}
                className="device-form-wrapper"
              >
                <PackageForm />
              </motion.div>
            )}
          </AnimatePresence>
          {/* End package form */}
        </div>

        {/* PAGE SIDE CONTENT */}

        {/* DEVICES LIST */}
        <div className="devices-add-device-form-device-desc-wrapper">
          <AnimatePresence>
            {postFormModalOpened && (
              <motion.div
                initial={{
                  width: 0,
                  height: 0,
                  opacity: 0,
                  translateX: '-700px',
                  transition: {
                    opacity: { ease: 'linear', duration: 0.3 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                animate={{
                  width: '100%',
                  height: 'auto',
                  opacity: 1,
                  translateX: 0,
                  transition: {
                    delay: 0.7,
                    opacity: { ease: 'linear', duration: 0.8 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                exit={{
                  width: 400,
                  height: 0,
                  opacity: 0,
                  translateX: '-400px',
                  transition: {
                    opacity: { ease: 'linear', duration: 0.5 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
              >
                <PackageDescription item={watchClientPackage} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* END PAGE SIDE CONTENT */}
      </div>

      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}

      {/* confirm clients modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={opened}
        modalType={type}
        setModalOpened={(v) =>
          dispatch(
            setConfirmModal({
              opened: v,
              type: ''
            })
          )
        }
        handleAccept={handleChangeClientStatus}
      >
        {type === confirmModalTypesValues?.successModal && (
          <h3>{`هل انت متأكد من قبول العميل  ${
            selectedTableRow?.name || ''
          }`}</h3>
        )}
        {type === confirmModalTypesValues?.errorModal && (
          <h3>{`هل انت متأكد من رفض العميل  ${
            selectedTableRow?.name || ''
          }`}</h3>
        )}
      </ConfirmModal>
      {/* end confirm clients modal */}

      {/* delete package modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteModalOpened}
        modalType={confirmModalTypesValues?.errorModal}
        setModalOpened={(v) => dispatch(setDeleteModalOpened(v))}
        handleAccept={handleDeleteClient}
      >
        <h3>{`هل انت متأكد من حذف العميل ${selectedTableRow?.name || ''}`}</h3>
      </ConfirmModal>
      {/* end delete package modal */}

      {isSubmitting && <LoadingModal />}
    </div>
  );
};

export default ClientsPage;
