/* eslint-disable react-hooks/exhaustive-deps */
import './ArchivePage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { deleteClientAction } from '../../redux/clients-slice/clientsSliceThunkApi';
import ClientsPageFilter from './ArchivePageFilter';
import ClientsPageTable from './ArchivePageTable';
import { getArchivesAction } from '../../redux/archive-slice/archiveSliceThunkApi';

const ArchivePage = () => {
  const dispatch = useDispatch();
  const isLoadingTableData = useSelector(
    (store) => store.archive.isLoadingTableData
  );
  const fetchDataCount = useSelector((store) => store.archive.fetchDataCount);
  const isSubmitting = useSelector((store) => store.archive.isSubmitting);
  const { search } = useLocation();
  const values = queryString.parse(search);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let isMounted = true;

  useEffect(() => {
    if (isMounted)
      dispatch(
        getArchivesAction({
          filterObj: values
        })
      );

    return function () {
      isMounted = false;
    };
  }, [fetchDataCount, search]);

  if (isLoadingTableData) {
    return (
      <div className="archives-page">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 360
          }}
          className="loading-wrapper"
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      </div>
    );
  }
  return (
    <div className="clients-page">
      <div className="page-content">
        <motion.div
          // layout
          initial={{
            width: 0,
            height: 0,
            opacity: 0,
            translateX: 700,
            transition: {
              opacity: { ease: 'linear', duration: 0.3 },
              width: { ease: 'linear', duration: 0.5 },
              translateX: { ease: 'linear', duration: 0.5 }
            }
          }}
          animate={{
            width: '100%',
            height: 'auto',
            opacity: 1,
            translateX: 0,
            transition: {
              delay: 0.7,
              opacity: { ease: 'linear', duration: 0.8 },
              width: { ease: 'linear', duration: 0.5 },
              translateX: { ease: 'linear', duration: 0.5 }
            }
          }}
          exit={{
            width: 400,
            height: 0,
            opacity: 0,
            translateX: 400,
            transition: {
              opacity: { ease: 'linear', duration: 0.5 },
              width: { ease: 'linear', duration: 0.5 },
              translateX: { ease: 'linear', duration: 0.5 }
            }
          }}
          className="page-main-content"
        >
          <div className="page-title">العملاء</div>
          <ClientsPageFilter />
          <ClientsPageTable />
        </motion.div>
      </div>

      {isSubmitting && <LoadingModal />}
    </div>
  );
};

export default ArchivePage;
