import { createSlice } from '@reduxjs/toolkit';
import {
  incrementCounterAction,
  decrementCounterAction,
  incrementByAmountAction
} from './counterActions';

const INITIAL_VALUES = {
  count: 0
};

const counterSlice = createSlice({
  name: 'Counter',
  initialState: {
    ...INITIAL_VALUES
  },
  reducers: {
    incrementCounter: incrementCounterAction,
    decrementCounter: decrementCounterAction,
    incrementByAmount: incrementByAmountAction
  }
});

export const { incrementCounter, decrementCounter, incrementByAmount } =
  counterSlice.actions;

export default counterSlice.reducer;
