import React from 'react';

const ServicesIconAppbar = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.99998 15.1667H5.99998C4.16665 15.1667 2.83331 13.8334 2.83331 12V4.00004C2.83331 2.16671 4.16665 0.833374 5.99998 0.833374H9.99998C11.8333 0.833374 13.1666 2.16671 13.1666 4.00004V12C13.1666 13.8334 11.8333 15.1667 9.99998 15.1667ZM5.99998 1.83337C4.49998 1.83337 3.83331 2.92004 3.83331 4.00004V12C3.83331 13.08 4.49998 14.1667 5.99998 14.1667H9.99998C11.5 14.1667 12.1666 13.08 12.1666 12V4.00004C12.1666 2.92004 11.5 1.83337 9.99998 1.83337H5.99998Z"
        fill={color || '#fff'}
      />
      <path
        d="M12.6666 11.1733H3.33331C3.05998 11.1733 2.83331 10.9467 2.83331 10.6733C2.83331 10.4 3.05998 10.1733 3.33331 10.1733H12.6666C12.94 10.1733 13.1666 10.4 13.1666 10.6733C13.1666 10.9467 12.94 11.1733 12.6666 11.1733Z"
        fill={color || '#fff'}
      />
      <path
        d="M1.33331 13.8333C1.05998 13.8333 0.833313 13.6066 0.833313 13.3333V2.66663C0.833313 2.39329 1.05998 2.16663 1.33331 2.16663C1.60665 2.16663 1.83331 2.39329 1.83331 2.66663V13.3333C1.83331 13.6066 1.60665 13.8333 1.33331 13.8333Z"
        fill={color || '#fff'}
      />
      <path
        d="M14.6667 13.8333C14.3934 13.8333 14.1667 13.6066 14.1667 13.3333V2.66663C14.1667 2.39329 14.3934 2.16663 14.6667 2.16663C14.94 2.16663 15.1667 2.39329 15.1667 2.66663V13.3333C15.1667 13.6066 14.94 13.8333 14.6667 13.8333Z"
        fill={color || '#fff'}
      />
    </svg>
  );
};

export default ServicesIconAppbar;
