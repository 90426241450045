/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import './PackageForm.scss';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useDispatch, useSelector } from 'react-redux';
import Xicon from '../../common/icons/Xicon';
import FileInput from '../../common/file-input/FileInput';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import {
  setPackageFormOpenedAction,
  setWatchPackageDevice
} from '../../redux/packages-slice/appPackagesSlice';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import { getPackagesStatusArr } from '../../const-values/packagesStatus';
import { getPackageFeaturesArr } from '../../const-values/packageFeaturesValues';
import {
  createAppPackageAction,
  updateAppPackageAction
} from '../../redux/packages-slice/appPackagesSliceThunkApi';

const schema = Yup.object().shape({
  name_ar: Yup.string().required('من فضلك ادخل اسم الجهاز بالعربية'),
  name_en: Yup.string().required('من فضلك ادخل اسم الجهاز بالانجليزية')
});

const { medalIcon, deviceIcon, fontSizeIcon, imageIcon } = imagesSrcs;

const emptyFormValues = {
  name_ar: '',
  name_en: '',
  duration_ar: '',
  duration_en: '',
  desc_ar: '',
  desc_en: '',
  days: '',
  price: '',
  discountValue: '',
  status: '',
  device_id: '',
  features: [],
  icon: null
};

const PackageForm = () => {
  const dispatch = useDispatch();
  const selectedTableRow = useSelector(
    (store) => store.appPackages.selectedTableRow
  );
  ///////////////
  // brands selectors
  const fetchedDevices = useSelector((store) => store.devices.fetchedDevices);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    unregister,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: emptyFormValues
  });

  console.log('watch : ', watch());
  useEffect(() => {
    if (fetchedDevices?.length > 0) {
      const foundWatchedDevice = fetchedDevices.find(
        (device) => device?.id == watch('device_id')
      );
      if (foundWatchedDevice)
        dispatch(setWatchPackageDevice(foundWatchedDevice));
    }
    return () => {
      dispatch(setWatchPackageDevice(null));
    };
  }, [watch('device_id')]);

  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      setValue('name_ar', selectedTableRow?.name_ar || '');
      setValue('name_en', selectedTableRow?.name_en || '');
      setValue('duration_ar', selectedTableRow?.duration_ar || '');
      setValue('duration_en', selectedTableRow?.duration_en || '');
      setValue('desc_ar', selectedTableRow?.desc_ar || '');
      setValue('desc_en', selectedTableRow?.desc_en || '');
      setValue('days', selectedTableRow?.days || '');
      setValue('price', selectedTableRow?.price || '');
      setValue('discountValue', selectedTableRow?.discountValue || '');
      setValue(
        'device_id',
        selectedTableRow?.device?.id ? String(selectedTableRow.device.id) : ''
      );
      setValue(
        'status',
        selectedTableRow?.status ? String(selectedTableRow.status) : ''
      );
      if (selectedTableRow?.features?.length > 0)
        setValue(
          'features',
          selectedTableRow.features.map((item) => item?.name)
        );
    }
  }, [selectedTableRow]);
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('name_ar', data.name_ar || '');
    formData.append('name_en', data.name_en || '');
    formData.append('duration_ar', data.duration_ar || '');
    formData.append('duration_en', data.duration_en || '');
    formData.append('desc_ar', data.desc_ar || '');
    formData.append('desc_en', data.desc_en || '');
    formData.append('price', data.price || '');
    formData.append('device_id', data.device_id || '');
    formData.append('discountValue', data.discountValue || '');
    formData.append('status', data.status || '');
    if (data?.features?.length > 0) {
      for (let i of data.features) formData.append('features[]', i);
    }
    if (data?.icon?.length > 0) {
      formData.append('icon', data.icon[0]);
    }
    if (!selectedTableRow) {
      dispatch(
        createAppPackageAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    } else {
      formData.append('package_id', selectedTableRow?.id || '');

      dispatch(
        updateAppPackageAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form package-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <p className="form-title">
        إضافة باقة جديدة
        <button
          type="button"
          className="back-btn"
          onClick={() => {
            dispatch(setPackageFormOpenedAction(false));
          }}
        >
          {/* <ArrowToLeftIcon color="#40404199" /> */}
          <Xicon color="#40404199" />
        </button>
      </p>
      <div className="form-body">
        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>إســم الباقة بالعربية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="name_ar"
              type="text"
              placeholder={'إســم الباقة بالعربية...'}
              // label="الاســــم"
              errorMsg={errors?.name_ar?.message}
              validateStatus={errors?.name_ar ? 'error' : ''}
              control={control}
            />
          </div>

          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>إســم الباقة بالانجليزية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="name_en"
              type="text"
              placeholder={'إســم الباقة بالانجليزية...'}
              // label="الاســــم"
              errorMsg={errors?.name_en?.message}
              validateStatus={errors?.name_en ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        {/*  */}
        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>مدة الباقة بالعربية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="duration_ar"
              type="text"
              placeholder={'مدة الباقة بالعربية...'}
              // label="الاســــم"
              errorMsg={errors?.duration_ar?.message}
              validateStatus={errors?.duration_ar ? 'error' : ''}
              control={control}
            />
          </div>

          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>مدة الباقة بالانجليزية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="duration_en"
              type="text"
              placeholder={'مدة الباقة بالانجليزية...'}
              // label="الاســــم"
              errorMsg={errors?.duration_en?.message}
              validateStatus={errors?.duration_en ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        {/*  */}
        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>سعر الباقة</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="price"
              type="text"
              placeholder={'سعر الباقة...'}
              // label="الاســــم"
              errorMsg={errors?.price?.message}
              validateStatus={errors?.price ? 'error' : ''}
              control={control}
            />
          </div>

          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>نسبة الخصم</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="discountValue"
              type="text"
              placeholder={'نسبة الخصم...'}
              // label="الاســــم"
              errorMsg={errors?.discountValue?.message}
              validateStatus={errors?.discountValue ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        {/*  */}

        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={deviceIcon} alt="name" />
              <span>عدد ايام الباقة</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="days"
              type="text"
              placeholder={'عدد ايام الباقة...'}
              // label="الاســــم"
              errorMsg={errors?.days?.message}
              validateStatus={errors?.days ? 'error' : ''}
              control={control}
            />
          </div>
          <div className="select-label-wrap shared-app-select-option-label-wrapper">
            <p className="outside-label">
              <img src={deviceIcon} alt="name" />
              أختار الجهاز
            </p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name={`device_id`}
                errorMsg={errors?.device_id && errors.device_id.message}
                validateStatus={errors?.device_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder="أختار الجهاز"
                options={
                  fetchedDevices?.length > 0 &&
                  fetchedDevices.map((obj) => ({
                    title: obj?.name,
                    value: obj?.id
                  }))
                }
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="package-form"
              />
            </div>
          </div>
        </div>

        {/*  */}

        {/*  */}
        <div className="custom-select-wrap without-icon shared-app-select-option-label-wrapper multiple-select-option">
          <p className="outside-label">
            <img src={fontSizeIcon} alt="name" />
            مميزات الباقة
          </p>
          <AntdSelectOption
            name={`features`}
            errorMsg={errors?.features && errors.features.message}
            validateStatus={errors?.features ? 'error' : ''}
            control={control}
            setValue={setValue}
            placeholder="مميزات الباقة"
            mode="tags"
            options={
              getPackageFeaturesArr()?.length > 0 &&
              getPackageFeaturesArr().map((obj) => ({
                title: obj?.name,
                value: obj?.value
              }))
            }
            showSearch={true}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            formClassName="package-form"
          />
        </div>
        {/*  */}

        <div className="select-label-wrap shared-app-select-option-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            حالة الباقة
          </p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`status`}
              errorMsg={errors?.status && errors.status.message}
              validateStatus={errors?.status ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="حالة الباقة"
              options={
                getPackagesStatusArr()?.length > 0 &&
                getPackagesStatusArr().map((obj) => ({
                  title: obj?.name,
                  value: obj?.id
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="package-form"
            />
          </div>
        </div>

        {/* DESC */}
        <div className="shared-app-text-field-label-wrapper is-text-area">
          <p className="outside-label">
            <img src={deviceIcon} alt="name" />
            <span>تفاصيل الباقة بالعربية</span>
          </p>
          <AntdTextarea
            className="form-text-field"
            rows={5}
            name="desc_ar"
            type="text"
            placeholder={'تفاصيل الباقة بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.desc_ar?.message}
            validateStatus={errors?.desc_ar ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper is-text-area">
          <p className="outside-label">
            <img src={deviceIcon} alt="name" />
            <span>تفاصيل الباقة بالانجليزية</span>
          </p>
          <AntdTextarea
            className="form-text-field"
            rows={5}
            name="desc_en"
            type="text"
            placeholder={'تفاصيل الباقة بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.desc_en?.message}
            validateStatus={errors?.desc_en ? 'error' : ''}
            control={control}
          />
        </div>
        {/* END DESC */}

        <div className="file-inputs-wrap">
          <FileInput
            name="icon"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label={
              <p
                className="outside-label"
                style={{
                  color: '#404041',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  marginBottom: 8,
                  paddingRight: 8
                }}
              >
                <img src={imageIcon} alt="name" />
                صورة الجهاز
              </p>
            }
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب الصورة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedTableRow?.icon ? [{ url: selectedTableRow.icon }] : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.icon?.message}
          />
        </div>

        <div className="modal-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            onClick={() => {
              dispatch(setPackageFormOpenedAction(false));
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {/* {isLoadingSignin && <LoadingModal />} */}
    </Form>
  );
};

export default PackageForm;
