import React from 'react';

const PartnersIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.9999 5.27331C11.9799 5.27331 11.9665 5.27331 11.9465 5.27331H11.9132C10.6532 5.23331 9.7132 4.25997 9.7132 3.05997C9.7132 1.8333 10.7132 0.839966 11.9332 0.839966C13.1532 0.839966 14.1532 1.83997 14.1532 3.05997C14.1465 4.26663 13.2065 5.23997 12.0065 5.27997C12.0065 5.2733 12.0065 5.27331 11.9999 5.27331ZM11.9332 1.8333C11.2599 1.8333 10.7132 2.37997 10.7132 3.0533C10.7132 3.7133 11.2265 4.24664 11.8865 4.27331C11.8932 4.26664 11.9465 4.26664 12.0065 4.27331C12.6532 4.23997 13.1532 3.70664 13.1599 3.0533C13.1599 2.37997 12.6132 1.8333 11.9332 1.8333Z"
        fill={color || '#fff'}
      />
      <path
        d="M12.0067 10.1867C11.7467 10.1867 11.4867 10.1667 11.2267 10.1201C10.9534 10.0734 10.7734 9.81339 10.82 9.54006C10.8667 9.26672 11.1267 9.08672 11.4 9.13339C12.22 9.27339 13.0867 9.12006 13.6667 8.7334C13.98 8.52673 14.1467 8.26672 14.1467 8.00672C14.1467 7.74672 13.9734 7.49339 13.6667 7.28672C13.0867 6.90005 12.2067 6.74672 11.38 6.89339C11.1067 6.94672 10.8467 6.76006 10.8 6.48672C10.7534 6.21339 10.9334 5.95339 11.2067 5.90673C12.2934 5.71339 13.42 5.92005 14.22 6.45339C14.8067 6.84672 15.1467 7.40672 15.1467 8.00672C15.1467 8.60006 14.8134 9.16673 14.22 9.56673C13.6134 9.96673 12.8267 10.1867 12.0067 10.1867Z"
        fill={color || '#fff'}
      />
      <path
        d="M3.98001 5.27338C3.97334 5.27338 3.96667 5.27338 3.96667 5.27338C2.76667 5.23338 1.82667 4.26004 1.82001 3.06004C1.82001 1.83337 2.82001 0.833374 4.04001 0.833374C5.26001 0.833374 6.26001 1.83338 6.26001 3.05338C6.26001 4.26004 5.32001 5.23338 4.12001 5.27338L3.98001 4.77338L4.02667 5.27338C4.01334 5.27338 3.99334 5.27338 3.98001 5.27338ZM4.04667 4.27338C4.08667 4.27338 4.12001 4.27337 4.16001 4.28004C4.75334 4.25337 5.27334 3.72004 5.27334 3.06004C5.27334 2.3867 4.72668 1.84004 4.05334 1.84004C3.38001 1.84004 2.83334 2.3867 2.83334 3.06004C2.83334 3.71337 3.34001 4.24004 3.98667 4.28004C3.99334 4.27337 4.02001 4.27338 4.04667 4.27338Z"
        fill={color || '#fff'}
      />
      <path
        d="M3.97331 10.1867C3.15331 10.1867 2.36665 9.96673 1.75998 9.56673C1.17331 9.1734 0.833313 8.60672 0.833313 8.00672C0.833313 7.41339 1.17331 6.84672 1.75998 6.45339C2.55998 5.92005 3.68665 5.71339 4.77331 5.90673C5.04665 5.95339 5.22665 6.21339 5.17998 6.48672C5.13331 6.76006 4.87331 6.94672 4.59998 6.89339C3.77331 6.74672 2.89998 6.90005 2.31331 7.28672C1.99998 7.49339 1.83331 7.74672 1.83331 8.00672C1.83331 8.26672 2.00665 8.52673 2.31331 8.7334C2.89331 9.12006 3.75998 9.27339 4.57998 9.13339C4.85331 9.08672 5.11331 9.27339 5.15998 9.54006C5.20665 9.81339 5.02665 10.0734 4.75331 10.1201C4.49331 10.1667 4.23331 10.1867 3.97331 10.1867Z"
        fill={color || '#fff'}
      />
      <path
        d="M7.99986 10.2533C7.97986 10.2533 7.96653 10.2533 7.94653 10.2533H7.9132C6.6532 10.2133 5.7132 9.23995 5.7132 8.03995C5.7132 6.81328 6.7132 5.81995 7.9332 5.81995C9.1532 5.81995 10.1532 6.81995 10.1532 8.03995C10.1465 9.24661 9.20653 10.2199 8.00653 10.2599C8.00653 10.2533 8.00653 10.2533 7.99986 10.2533ZM7.9332 6.81328C7.25986 6.81328 6.7132 7.35995 6.7132 8.03328C6.7132 8.69328 7.22653 9.22662 7.88653 9.25329C7.8932 9.24662 7.94653 9.24662 8.00653 9.25329C8.65319 9.21995 9.1532 8.68662 9.15986 8.03328C9.15986 7.36662 8.6132 6.81328 7.9332 6.81328Z"
        fill={color || '#fff'}
      />
      <path
        d="M7.99988 15.1734C7.19988 15.1734 6.39988 14.9667 5.77988 14.5467C5.19321 14.1534 4.85321 13.5934 4.85321 12.9934C4.85321 12.4 5.18654 11.8267 5.77988 11.4334C7.02654 10.6067 8.97988 10.6067 10.2199 11.4334C10.8065 11.8267 11.1465 12.3867 11.1465 12.9867C11.1465 13.58 10.8132 14.1534 10.2199 14.5467C9.59988 14.96 8.79988 15.1734 7.99988 15.1734ZM6.33321 12.2734C6.01988 12.48 5.85321 12.74 5.85321 13C5.85321 13.26 6.02654 13.5134 6.33321 13.72C7.23321 14.3267 8.75988 14.3267 9.65988 13.72C9.97321 13.5134 10.1399 13.2534 10.1399 12.9934C10.1399 12.7334 9.96655 12.48 9.65988 12.2734C8.76655 11.6667 7.23988 11.6734 6.33321 12.2734Z"
        fill={color || '#fff'}
      />
    </svg>
  );
};

export default PartnersIcon;
