/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import myNotificationsApi from '../../apis/auth/myNotificationsApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import UserContext from '../user-context/UserProvider';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (v) => {},
  isLoadingNotifications: false,
  setIsLoadingNotifications: (v) => {},
  myFetchedNotifications: [],
  setMyFetchedNotifications: (v) => {},
  notificationsPagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  // FCM
  fcmNotificationFetchCount: 0,
  setFcmNotificationFetchCount: (v) => {},
  fcmNotificationPayload: null,
  setFcmNotificationPayload: (v) => {}
};

const MyNotificationsContext = createContext(INITIAL_VALUES);

export const MyNotificationsProvider = ({ children }) => {
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(
    INITIAL_VALUES.isLoadingNotifications
  );
  const [myFetchedNotifications, setMyFetchedNotifications] = useState(
    INITIAL_VALUES.myFetchedNotifications
  );
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [notificationsPagination, setNotificationsPagination] = useState(
    INITIAL_VALUES.notificationsPagination
  );
  // FCM
  const [fcmNotificationFetchCount, setFcmNotificationFetchCount] = useState(
    INITIAL_VALUES.fcmNotificationFetchCount
  );
  const [fcmNotificationPayload, setFcmNotificationPayload] = useState(
    INITIAL_VALUES.fcmNotificationPayload
  );

  const { user } = useContext(UserContext);
  const { i18n } = useTranslation();
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    if (user) {
      let isMounted = true;
      setIsLoadingNotifications(true);
      customApiRequest(
        myNotificationsApi(user?.token, i18n.language),
        (res) => {
          if (isMounted) {
            setIsLoadingNotifications(false);
            if (checkRes(res) && res.data?.data?.length >= 0) {
              const data = res.data.data;
              setMyFetchedNotifications(data);
            } else {
              setMyFetchedNotifications([]);
            }
          }
        },
        (error) => {
          setIsLoadingNotifications(false);
        }
      );

      return () => {
        isMounted = false;
      };
    }
  }, [fetchCount, i18n.language]);

  return (
    <MyNotificationsContext.Provider
      value={{
        isLoadingNotifications,
        setIsLoadingNotifications,
        myFetchedNotifications,
        setMyFetchedNotifications,
        fetchCount,
        setFetchCount,
        notificationsPagination,
        setNotificationsPagination,
        //
        fcmNotificationFetchCount,
        setFcmNotificationFetchCount,
        fcmNotificationPayload,
        setFcmNotificationPayload
      }}
    >
      {children}
    </MyNotificationsContext.Provider>
  );
};

export default MyNotificationsContext;
