import React from 'react';

const InstagramIcon = ({ color, width, height, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '104'}
      height={height || '104'}
      viewBox="0 0 104 104"
      fill="none"
    >
      <path
        d="M65 98.5832H39C15.47 98.5832 5.41666 88.5298 5.41666 64.9998V38.9998C5.41666 15.4698 15.47 5.4165 39 5.4165H65C88.53 5.4165 98.5833 15.4698 98.5833 38.9998V64.9998C98.5833 88.5298 88.53 98.5832 65 98.5832ZM39 11.9165C19.0233 11.9165 11.9167 19.0232 11.9167 38.9998V64.9998C11.9167 84.9765 19.0233 92.0832 39 92.0832H65C84.9767 92.0832 92.0833 84.9765 92.0833 64.9998V38.9998C92.0833 19.0232 84.9767 11.9165 65 11.9165H39Z"
        fill={color || '#2A9D7C'}
      />
      <path
        d="M52 70.4168C41.86 70.4168 33.5833 62.1402 33.5833 52.0002C33.5833 41.8602 41.86 33.5835 52 33.5835C62.14 33.5835 70.4167 41.8602 70.4167 52.0002C70.4167 62.1402 62.14 70.4168 52 70.4168ZM52 40.0835C45.4133 40.0835 40.0833 45.4135 40.0833 52.0002C40.0833 58.5868 45.4133 63.9168 52 63.9168C58.5867 63.9168 63.9167 58.5868 63.9167 52.0002C63.9167 45.4135 58.5867 40.0835 52 40.0835Z"
        fill={color || '#2A9D7C'}
      />
      <path
        d="M73.6667 32.5001C73.1033 32.5001 72.54 32.3701 72.02 32.1534C71.5 31.9367 71.0233 31.6334 70.59 31.2434C70.2 30.8101 69.8533 30.3334 69.6367 29.8134C69.42 29.2934 69.3333 28.7301 69.3333 28.1667C69.3333 27.6034 69.42 27.0401 69.6367 26.5201C69.8967 25.9567 70.2 25.5234 70.59 25.0901C70.8067 24.9167 71.0233 24.7001 71.24 24.5701C71.5 24.3967 71.76 24.2667 72.02 24.1801C72.28 24.0501 72.54 23.9634 72.8433 23.9201C74.23 23.6167 75.7033 24.0934 76.7433 25.0901C77.1333 25.5234 77.4367 25.9567 77.6533 26.5201C77.87 27.0401 78 27.6034 78 28.1667C78 28.7301 77.87 29.2934 77.6533 29.8134C77.4367 30.3334 77.1333 30.8101 76.7433 31.2434C76.31 31.6334 75.8333 31.9367 75.3133 32.1534C74.7933 32.3701 74.23 32.5001 73.6667 32.5001Z"
        fill={color || '#2A9D7C'}
      />
    </svg>
  );
};

export default InstagramIcon;
