import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  createBrandAction,
  deleteBrandAction,
  editBrandAction,
  getBrandsAction
} from './brandsSliceThunkApis';

const initialState = {
  isLoadingJsonData: false,
  jsonData: [],
  /////////////////////
  isLoadingTableData: false,
  fetchedBrands: [],
  fetchDataCount: 0,
  brandFormOpened: false,
  deleteModalOpened: false,
  isSubmitting: false,
  selectedTableRow: null,
  tablePagination: {
    current_page: 1,
    par_page: 0,
    total: 0
  }
};

const BrandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    setIsLoadingBrands: (stateSlice, action) => {
      stateSlice.isLoadingTableData = action.payload;
    },
    setBrandFormOpenedAction: (sliceState, action) => {
      sliceState.brandFormOpened = action.payload;
    },
    setDeleteModalOpened: (sliceState, action) => {
      sliceState.deleteModalOpened = action.payload;
    },
    setSelectedTableRow: (sliceState, action) => {
      sliceState.selectedTableRow = action.payload;
    }
  },
  extraReducers: {
    // get all brands
    [getBrandsAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getBrandsAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedBrands = data;
    },
    [getBrandsAction.rejected]: (state) => {
      state.isLoadingTableData = false;
    },
    // create brand
    [createBrandAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [createBrandAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action?.payload?.res;
      if (checkRes(res)) {
        // successNotification({
        //   title: 'العملية تمت بنجاح',
        //   message: res?.data?.message || 'تمت اضافة البراند بنحاح'
        // });
        state.fetchDataCount += 1;
        state.brandFormOpened = false;
      }
      // else {
      //   errorNotification({
      //     title: 'العملية فشلت',
      //     message: res?.data?.message || 'حدث خطأ'
      //   });
      // }
    },
    [createBrandAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    // edit brand
    [editBrandAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [editBrandAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action?.payload?.res;
      if (checkRes(res)) {
        // successNotification({
        //   title: 'العملية تمت بنجاح',
        //   message: res?.data?.message || 'تمت اضافة البراند بنحاح'
        // });
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.brandFormOpened = false;
      }
      // else {
      //   errorNotification({
      //     title: 'العملية فشلت',
      //     message: res?.data?.message || 'حدث خطأ'
      //   });
      // }
    },
    [editBrandAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    [deleteBrandAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [deleteBrandAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.deleteModalOpened = false;
      }
    },
    [deleteBrandAction.rejected]: (state) => {
      state.isSubmitting = false;
    }
  }
});

const brandsReducer = BrandsSlice.reducer;

export const {
  setIsLoadingBrands,
  setBrandFormOpenedAction,
  setDeleteModalOpened,
  setSelectedTableRow
} = BrandsSlice.actions;

export default brandsReducer;
