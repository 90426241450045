/* eslint-disable eqeqeq */

// 1 => active
// 2 => not active

export const brandsStatus = {
  active: 1,
  notActive: 2
};

export const getBrandsStatusArr = (t) => {
  return [
    {
      id: 1,
      name: 'مفعلة',
      value: brandsStatus?.active
    },
    {
      id: 2,
      name: 'غير مفعلة',
      value: brandsStatus?.notActive
    }
  ];
};
