import { createAsyncThunk } from '@reduxjs/toolkit';
import createInfluencerApi from '../../apis/influencers-apis/createInfluencerApi';
import deleteInfluencerApi from '../../apis/influencers-apis/deleteInfluencerApi';
import getAllInfluencersApi from '../../apis/influencers-apis/getAllInfluencersApi';
import getInfluencersSocialApi from '../../apis/influencers-apis/getInfluencersSocialApi';
import customApiRequest from '../../helpers/customApiRequest';

export const getInfluencersAction = createAsyncThunk(
  'influencers/getInfluencersAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getAllInfluencersApi(filterObj),
      thunkAPI
    });
  }
);
// get socials
export const getInfluencersSocialsAction = createAsyncThunk(
  'influencers/getInfluencersSocialsAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getInfluencersSocialApi(filterObj),
      thunkAPI
    });
  }
);

// create influencer
export const createInfluencerAction = createAsyncThunk(
  'influencers/createInfluencerAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createInfluencerApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
//
// update influencer
export const updateInfluencerAction = createAsyncThunk(
  'influencers/updateInfluencerAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createInfluencerApi(dataToBeSubmitted, true),
      thunkAPI
    });
  }
);
//

export const deleteInfluencerAction = createAsyncThunk(
  'influencers/deleteInfluencerAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteInfluencerApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
