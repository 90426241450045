import React from 'react';

const HomeIcon = ({ color, width, height, ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.86 15.16H4.13998C2.31331 15.16 0.833313 13.6733 0.833313 11.8466V6.90664C0.833313 5.99998 1.39331 4.85998 2.11331 4.29998L5.70665 1.49998C6.78665 0.659976 8.51331 0.619976 9.63331 1.40664L13.7533 4.29331C14.5466 4.84664 15.1666 6.03331 15.1666 6.99998V11.8533C15.1666 13.6733 13.6866 15.16 11.86 15.16ZM6.31998 2.28664L2.72665 5.08664C2.25331 5.45998 1.83331 6.30664 1.83331 6.90664V11.8466C1.83331 13.12 2.86665 14.16 4.13998 14.16H11.86C13.1333 14.16 14.1666 13.1266 14.1666 11.8533V6.99998C14.1666 6.35998 13.7066 5.47331 13.18 5.11331L9.05998 2.22664C8.29998 1.69331 7.04665 1.71998 6.31998 2.28664Z"
        fill={color || '#fff'}
      />
      <path
        d="M4.99998 11.4999C4.87331 11.4999 4.74665 11.4532 4.64665 11.3532C4.45331 11.1599 4.45331 10.8399 4.64665 10.6465L6.77998 8.51321C6.88665 8.40655 7.02665 8.35321 7.17998 8.36655C7.32665 8.37988 7.45998 8.45988 7.54665 8.58655L8.27331 9.67988L10.64 7.31321C10.8333 7.11988 11.1533 7.11988 11.3466 7.31321C11.54 7.50655 11.54 7.82655 11.3466 8.01988L8.54665 10.8199C8.43998 10.9265 8.29998 10.9799 8.14665 10.9665C7.99998 10.9532 7.86665 10.8732 7.77998 10.7465L7.05331 9.65321L5.35331 11.3532C5.25331 11.4532 5.12665 11.4999 4.99998 11.4999Z"
        fill={color || '#fff'}
      />
      <path
        d="M11 9.50008C10.7267 9.50008 10.5 9.27341 10.5 9.00008V8.16675H9.66669C9.39335 8.16675 9.16669 7.94008 9.16669 7.66675C9.16669 7.39341 9.39335 7.16675 9.66669 7.16675H11C11.2734 7.16675 11.5 7.39341 11.5 7.66675V9.00008C11.5 9.27341 11.2734 9.50008 11 9.50008Z"
        fill={color || '#fff'}
      />
    </svg>
  );
};

export default HomeIcon;
