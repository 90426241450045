/* eslint-disable react-hooks/exhaustive-deps */
import Routes from './Routes';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import { ReactNotifications } from 'react-notifications-component';
import axiosDefaults from '../../axiosDefaults';
import { useContext } from 'react';
import UserContext from '../../contexts/user-context/UserProvider';
import { ConfigProvider } from 'antd';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import myInfoApi from '../../apis/auth/myInfoApi';
import checkRes from '../../utils/checkRes';
import routerLinks from './routerLinks';
import { useNavigate } from 'react-router-dom';

// axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
function App() {
  const { i18n } = useTranslation();
  const { user, removeCurrentUser, setCurrentUser } = useContext(UserContext);
  axiosDefaults(user, i18n.language);

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.dir()]);

  const navigate = useNavigate();
  const customApiRequest = useCustomApiRequest();
  // console.log('user : ', user);
  useEffect(() => {
    let isMounted = true;
    if (user) {
      customApiRequest(
        myInfoApi(user?.token, i18n.language),
        (res) => {
          if (isMounted) {
            if (checkRes(res)) {
              setCurrentUser({
                ...res?.data?.data,
                token: user?.token,
                accountType: user.accountType,
                activated: user.activated
              });
            } else {
              removeCurrentUser();
              navigate(routerLinks?.signinPage);
            }
          }
        },
        (error) => {}
      );
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <ConfigProvider direction={i18n.dir()}>
      <div className="App">
        <ReactNotifications className={i18n.dir()} />
        <ToastContainer closeButton={false} />
        <Routes />
      </div>
    </ConfigProvider>
  );
}

export default App;
