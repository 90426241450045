/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useDispatch, useSelector } from 'react-redux';
import Xicon from '../../common/icons/Xicon';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import './InfluencerForm.scss';
import {
  createInfluencerAction,
  updateInfluencerAction
} from '../../redux/influcencers-slice/influencersSliceThunkApi';
import { setPostFormModalOpened } from '../../redux/influcencers-slice/influencersSlice';
import FileInput from '../../common/file-input/FileInput';
import CustomAntdDatePicker from '../../common/antd-form-components/CustomAntdDatePicker';
import { getPackageFeaturesArr } from '../../const-values/packageFeaturesValues';

const schema = Yup.object().shape({
  name: Yup.string().required('من فضلك ادخل اسم المسوق')
});

const { medalIcon, emailIcon, phoneIcon, imageIcon } = imagesSrcs;

const emptyFormValues = {
  name: '',
  phone: '',
  email: '',
  code: '',
  discount: '',
  startDate: '',
  days: '',
  commission: '',
  note: '',
  socials: []
};

const InfluencerForm = () => {
  const dispatch = useDispatch();
  const fetchedSocials = useSelector(
    (store) => store.influencers.fetchedSocials
  );
  const selectedTableRow = useSelector(
    (store) => store.influencers.selectedTableRow
  );
  ///////////////
  // brands selectors
  const fetchedPackages = useSelector(
    (store) => store.influencers.fetchedTableData
  );

  console.log('socials : ', fetchedSocials);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    register,
    unregister,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: emptyFormValues
  });

  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      setValue('name', selectedTableRow?.name || '');
      setValue('phone', selectedTableRow?.phone || '');
      setValue('email', selectedTableRow?.email || '');
      setValue('password', selectedTableRow?.password || '');
      setValue(
        'package_id',
        selectedTableRow?.subscription?.package?.id
          ? String(selectedTableRow.subscription.package.id)
          : ''
      );
    }
  }, [selectedTableRow, fetchedPackages]);
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('name', data.name || '');
    formData.append('phone', data.phone || '');
    formData.append('email', data.email || '');
    formData.append('code', data.code || '');
    formData.append('discount', data.discount || '');
    formData.append('startDate', data.startDate || '');
    formData.append('days', data.days || '');
    formData.append('commission', data.commission || '');
    formData.append('note', data.note || '');
    formData.append('socials', data.socials || '');

    if (!selectedTableRow) {
      dispatch(
        createInfluencerAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    } else {
      formData.append('influencer_id', selectedTableRow?.id || '');

      dispatch(
        updateInfluencerAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form influencer-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <p className="form-title">
        إضافة مسوق جديد
        <button
          type="button"
          className="back-btn"
          onClick={() => {
            dispatch(setPostFormModalOpened(false));
          }}
        >
          {/* <ArrowToLeftIcon color="#40404199" /> */}
          <Xicon color="#40404199" />
        </button>
      </p>
      <div className="form-body">
        <div className="two-cols-form">
          <div className="main-col">
            <div className="shared-app-text-field-label-wrapper">
              <p className="outside-label">
                <img src={medalIcon} alt="name" />
                <span>إســم المسوق</span>
              </p>
              <AntdTextField
                className="form-text-field"
                name="name"
                type="text"
                placeholder={'إســم المسوق...'}
                // label="الاســــم"
                errorMsg={errors?.name?.message}
                validateStatus={errors?.name ? 'error' : ''}
                control={control}
              />
            </div>

            <div className="shared-app-text-field-label-wrapper">
              <p className="outside-label">
                <img src={phoneIcon} alt="name" />
                <span>رقم الهاتف</span>
              </p>
              <AntdTextField
                className="form-text-field"
                name="phone"
                type="text"
                placeholder={'رقم الهاتف...'}
                // label="الاســــم"
                errorMsg={errors?.phone?.message}
                validateStatus={errors?.phone ? 'error' : ''}
                control={control}
              />
            </div>

            <div className="shared-app-text-field-label-wrapper">
              <p className="outside-label">
                <img src={emailIcon} alt="name" />
                <span>البريد الالكترونى</span>
              </p>
              <AntdTextField
                className="form-text-field"
                name="email"
                type="text"
                placeholder={'البريد الالكترونى...'}
                // label="الاســــم"
                errorMsg={errors?.email?.message}
                validateStatus={errors?.email ? 'error' : ''}
                control={control}
              />
            </div>

            <div className="shared-app-text-field-label-wrapper">
              <p className="outside-label">
                <img src={emailIcon} alt="name" />
                <span>كود دالخصم</span>
              </p>
              <AntdTextField
                className="form-text-field"
                name="code"
                type="text"
                placeholder={'كود دالخصم...'}
                // label="الاســــم"
                errorMsg={errors?.code?.message}
                validateStatus={errors?.code ? 'error' : ''}
                control={control}
              />
            </div>

            <div className="shared-app-text-field-label-wrapper">
              <p className="outside-label">
                <img src={emailIcon} alt="name" />
                <span>نسبة الخصم</span>
              </p>
              <AntdTextField
                className="form-text-field"
                name="discount"
                type="text"
                placeholder={'نسبة الخصم...'}
                // label="الاســــم"
                errorMsg={errors?.discount?.message}
                validateStatus={errors?.discount ? 'error' : ''}
                control={control}
              />
            </div>

            <label className="custom-date-input order-date-label">
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8
                }}
                className="label-span"
              >
                <img src={emailIcon} alt="name" />
                تاريخ بداية الخصم
              </span>
              <CustomAntdDatePicker
                disablePast
                control={control}
                name={`startDate`}
                placeholder="تاريخ بداية الخصم"
                className="order-discount-picker"
                formClassName="influencer-form"
                errorMsg={errors?.startDate && errors.startDate.message}
              />
            </label>

            <div className="file-inputs-wrap">
              <FileInput
                name="icon"
                // accept="image/png, image/jpg, image/jpeg, image/gif"
                label={
                  <p
                    className="outside-label"
                    style={{
                      color: '#404041',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                      marginBottom: 8,
                      paddingRight: 8
                    }}
                  >
                    <img src={imageIcon} alt="name" />
                    صورة المسوق
                  </p>
                }
                accept="image/*"
                multiple={false}
                setValue={setValue}
                watch={watch}
                register={register}
                unregister={unregister}
                // setUrls={setUrls}
                dropzoneText="اسحب الصورة وضعها هنا ..."
                className="product-images-dropzone"
                dropzoneUrls={
                  selectedTableRow?.icon ? [{ url: selectedTableRow.icon }] : []
                }
                canDelete={false}
                showError={false}
                errorMsg={errors?.icon?.message}
              />
            </div>
          </div>

          <div className="side-col">
            <div className="shared-app-text-field-label-wrapper">
              <p className="outside-label">
                <img src={emailIcon} alt="name" />
                <span>عمولة المسوق ( نسبة المسوق )</span>
              </p>
              <AntdTextField
                className="form-text-field"
                name="discount"
                type="text"
                placeholder="حدد عمولة المسوق المتفق عليها"
                // label="الاســــم"
                errorMsg={errors?.discount?.message}
                validateStatus={errors?.discount ? 'error' : ''}
                control={control}
              />
            </div>

            {/*  */}
            <div className="custom-select-wrap without-icon shared-app-select-option-label-wrapper multiple-select-option">
              <p className="outside-label">
                <img src={imagesSrcs?.fontSizeIcon} alt="name" />
                منصات التسوق
              </p>
              <AntdSelectOption
                name={`features`}
                errorMsg={errors?.features && errors.features.message}
                validateStatus={errors?.features ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder="حدد منصات التسوق"
                mode="tags"
                options={
                  getPackageFeaturesArr()?.length > 0 &&
                  getPackageFeaturesArr().map((obj) => ({
                    title: obj?.name,
                    value: obj?.value
                  }))
                }
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="package-form"
              />
            </div>
            {/*  */}
          </div>
        </div>

        <div className="modal-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            onClick={() => {
              dispatch(setPostFormModalOpened(false));
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {/* {isLoadingSignin && <LoadingModal />} */}
    </Form>
  );
};

export default InfluencerForm;
