import React from 'react';

const YoutubeIcon = ({ color, width, height, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '104'}
      height={height || '104'}
      viewBox="0 0 104 104"
      fill="none"
    >
      <path
        d="M73.6667 89.9168H30.3333C15.4267 89.9168 5.41667 79.9068 5.41667 65.0002V39.0002C5.41667 24.0935 15.4267 14.0835 30.3333 14.0835H73.6667C88.5733 14.0835 98.5833 24.0935 98.5833 39.0002V65.0002C98.5833 79.9068 88.5733 89.9168 73.6667 89.9168ZM30.3333 20.5835C19.1533 20.5835 11.9167 27.8202 11.9167 39.0002V65.0002C11.9167 76.1802 19.1533 83.4168 30.3333 83.4168H73.6667C84.8467 83.4168 92.0833 76.1802 92.0833 65.0002V39.0002C92.0833 27.8202 84.8467 20.5835 73.6667 20.5835H30.3333Z"
        fill={color || '#2A9D7C'}
      />
      <path
        d="M45.8901 67.2965C44.6335 67.2965 43.4201 66.9932 42.3368 66.3865C39.8234 64.9565 38.3499 62.0531 38.3499 58.4564V45.6298C38.3499 42.0331 39.7801 39.1299 42.3368 37.6999C44.8501 36.2699 48.1 36.5297 51.1767 38.3931L61.8801 44.8065C64.8268 46.5831 66.5166 49.2265 66.5166 52.0432C66.5166 54.8599 64.8268 57.5031 61.8801 59.2798L51.1767 65.6932C49.4 66.7332 47.5801 67.2965 45.8901 67.2965ZM45.8901 43.2031C45.7168 43.2031 45.5867 43.2464 45.5 43.2898C45.1967 43.4631 44.8499 44.2431 44.8499 45.5864V58.4132C44.8499 59.7132 45.1967 60.4932 45.5 60.7099C45.8467 60.8832 46.6701 60.7965 47.7968 60.1031L58.5 53.6899C59.5833 53.0399 59.9735 52.3898 59.9735 51.9998C59.9735 51.6098 59.5833 51.0031 58.5 50.3097L47.7968 43.8965C47.0168 43.4198 46.3235 43.2031 45.8901 43.2031Z"
        fill={color || '#2A9D7C'}
      />
    </svg>
  );
};

export default YoutubeIcon;
