import React from 'react';
import { useTranslation } from 'react-i18next';
import MainAppBar from '../../main-app-bar/MainAppBar';
import './DefaultAppLayout.scss';
import ActiveAppBarLink from '../../../utils/ActiveAppBarLink';
import DocTitleScrollTop from '../../../utils/DocTitleScrollTop';
import { useLocation } from 'react-router-dom';
import TopAppbar from './TopAppbar';

const DefaultAppLayout = ({ children }) => {
  const { i18n } = useTranslation();
  // const { Header, Content, Footer, Sider } = AntdLayout;
  ActiveAppBarLink();
  DocTitleScrollTop();
  const { pathname } = useLocation();

  return (
    <div
      className={`app-layout app-${i18n.dir()} ${
        pathname.includes('settings/help') ? 'in-help-page' : ''
      }`}
    >
      {/* <div className="custom-container"> */}
      <div className="layout-content">
        {!pathname.includes('invoice-details') &&
          !pathname.includes('settings/help') && <MainAppBar />}

        <div className="custom-container-wrapper">
          <div
            className="custom-container"
            style={{
              margin: 'unset'
            }}
          >
            <div className="top-appbar-children-wrapper">
              <TopAppbar />
              {children}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default DefaultAppLayout;
