const sharedRouterLinks = {
  // merchentDashboard: '/merchent-dashboard',
  homeRoute: '/',
  settingsRoute: '/settings',
  //
  appClientsRoute: '/clients',
  appAgentsRoute: '/agents',
  appPackagesRoute: '/app-packages',
  appPaymentTypesRoute: '/payment-types',
  appArchivesRoute: '/archived',
  //
  websiteAdminPanelRoute: '/website-panel',
  websitesServicesRoute: '/website-services',
  websitePartnersRoute: '/website-partners',
  websitePackagesRoute: '/website-packages',
  websiteHelpRoute: '/website-help',
  websiteStartExpRoute: '/website-starte-experience'
  //
};

export default sharedRouterLinks;
