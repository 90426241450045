/* eslint-disable eqeqeq */

import CustomImage from '../../common/custom-image/CustomImage';
import medalIcon from '../../assets/imgs/icons/packages-icons/medal.svg';
import posIcon from '../../assets/imgs/icons/packages-icons/pos-icon.svg';
import CommonTag from '../../common/common-tag/CommonTag';

const ArchiveTableColumns = ({
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  setConfirmModal,
  setDeleteModalOpened,
  i18n,
  t,
  ...props
}) => {
  const RenderTag = ({ iconSrc, title, className }) => {
    return <CommonTag title={title} iconSrc={iconSrc} className={className} />;
  };

  return [
    {
      title: '',
      dataIndex: 'image',
      render: (_, record) => {
        return (
          <div className="table-img-wrapper">
            <CustomImage className="table-package-img" src={record?.image} />
          </div>
        );
      }
    },
    {
      title: 'الاسم',
      dataIndex: 'name',
      render: (_, record) => {
        return record?.name || '----';
      }
    },
    {
      title: 'رقم الهاتف',
      dataIndex: 'phone',
      render: (_, record) => {
        return record?.phone || '----';
      }
    },
    {
      title: 'البريد الاكترونى',
      dataIndex: 'email',
      render: (_, record) => {
        return record?.email || '----';
      }
    },
    {
      title: 'البلد',
      dataIndex: 'country',
      render: (_, record) => {
        return record?.country?.name || '----';
      }
    },
    {
      title: 'الاشتراك',
      dataIndex: 'tags',
      render: (_, record) => {
        return !record?.subscription ? (
          '---'
        ) : (
          <div className="package-tags">
            <div className="subscription-tags">
              {record?.subscription?.package?.duration ? (
                <RenderTag
                  iconSrc={medalIcon}
                  title={`اشتراك ${
                    record?.subscription?.package?.duration || ''
                  }`}
                  className="yellow"
                />
              ) : null}
              <RenderTag
                iconSrc={medalIcon}
                title={`باقة ${
                  record?.subscription?.package?.price
                    ? `${record?.subscription?.package?.price} ${t(
                        'currency.sar'
                      )}`
                    : ''
                }`}
                className="yellow"
              />
            </div>
            <div className="device-tags">
              {record?.subscription?.package?.device?.name ? (
                <RenderTag
                  iconSrc={posIcon}
                  title={`${record?.subscription?.package?.device?.name} ${record?.subscription?.package?.device?.model}`}
                  className="green"
                />
              ) : null}
              {record?.subscription?.package?.device?.brand?.name ? (
                <RenderTag
                  iconSrc={posIcon}
                  title={`${record?.subscription?.package?.device.brand.name}`}
                  className="green"
                />
              ) : null}
            </div>
          </div>
        );
      }
    }

    // {
    //   title: 'عرض التفاصيل',
    //   dataIndex: 'details',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <Tooltip title="عرض التفاصيل">
    //         <Button
    //           size="large"
    //           type="dashed"
    //           shape="round"
    //           icon={<EyeOutlined />}
    //           onClick={() => handleViewDelegateDetails(record)}
    //         />
    //       </Tooltip>
    //     );
    //   }
    // }
  ];
};

export default ArchiveTableColumns;
