/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import './ClientForm.scss';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useDispatch, useSelector } from 'react-redux';
import Xicon from '../../common/icons/Xicon';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import {
  setPostFormModalOpened,
  setWatchClientPackage
} from '../../redux/clients-slice/clientsSlice';
import {
  createClientAction,
  updateClientAction
} from '../../redux/clients-slice/clientsSliceThunkApi';
import EyeOnIcon from '../../common/icons/EyeOnIcon';
import EyeOffIcon from '../../common/icons/EyeOffIcon';

const schema = Yup.object().shape({
  name: Yup.string().required('من فضلك ادخل اسم العميل')
});

const { medalIcon, deviceIcon, emailIcon, phoneIcon, lockIcon } = imagesSrcs;

const emptyFormValues = {
  name: '',
  phone: '',
  email: '',
  password: '',
  package_id: ''
};

const ClientForm = () => {
  const [passwrodVisible, setPasswordVisible] = useState(false);
  const dispatch = useDispatch();
  const selectedTableRow = useSelector(
    (store) => store.clients.selectedTableRow
  );
  ///////////////
  // brands selectors
  const fetchedPackages = useSelector(
    (store) => store.appPackages.fetchedTableData
  );
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: emptyFormValues
  });

  useEffect(() => {
    if (fetchedPackages?.length > 0) {
      const found = fetchedPackages.find((p) => p?.id == watch('package_id'));
      if (found) dispatch(setWatchClientPackage(found));
    }
    return () => {
      dispatch(setWatchClientPackage(null));
    };
  }, [watch('package_id')]);

  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      setValue('name', selectedTableRow?.name || '');
      setValue('phone', selectedTableRow?.phone || '');
      setValue('email', selectedTableRow?.email || '');
      setValue('password', selectedTableRow?.password || '');
      setValue(
        'package_id',
        selectedTableRow?.subscription?.package?.id
          ? String(selectedTableRow.subscription.package.id)
          : ''
      );
    }
  }, [selectedTableRow, fetchedPackages]);
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('name', data.name || '');
    formData.append('phone', data.phone || '');
    formData.append('email', data.email || '');
    formData.append('password', data.password || '');
    formData.append('package_id', data.package_id || '');

    if (!selectedTableRow) {
      dispatch(
        createClientAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    } else {
      formData.append('client_id', selectedTableRow?.id || '');

      dispatch(
        updateClientAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form client-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <p className="form-title">
        إضافة عميل جديد
        <button
          type="button"
          className="back-btn"
          onClick={() => {
            dispatch(setPostFormModalOpened(false));
          }}
        >
          {/* <ArrowToLeftIcon color="#40404199" /> */}
          <Xicon color="#40404199" />
        </button>
      </p>
      <div className="form-body">
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>إســم العميل</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="name"
            type="text"
            placeholder={'إســم العميل...'}
            // label="الاســــم"
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            control={control}
          />
        </div>

        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={phoneIcon} alt="name" />
            <span>رقم الهاتف</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="phone"
            type="text"
            placeholder={'رقم الهاتف...'}
            // label="الاســــم"
            errorMsg={errors?.phone?.message}
            validateStatus={errors?.phone ? 'error' : ''}
            control={control}
          />
        </div>

        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={emailIcon} alt="name" />
            <span>البريد الالكترونى</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="email"
            type="text"
            placeholder={'البريد الالكترونى...'}
            // label="الاســــم"
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            control={control}
          />
        </div>

        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={lockIcon} alt="name" />
            <span>كلمة المرور</span>
          </p>
          <div className="client-password-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="password"
              type={passwrodVisible ? 'text' : 'password'}
              placeholder="كلمة المرور..."
              errorMsg={errors?.password?.message}
              validateStatus={errors?.password ? 'error' : ''}
              control={control}
            />

            {watch('password') && (
              <div
                className="eye-icon-btn"
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </div>
            )}
          </div>
        </div>

        <div className="select-label-wrap shared-app-select-option-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            أختار الباقة
          </p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`package_id`}
              errorMsg={errors?.package_id && errors.package_id.message}
              validateStatus={errors?.package_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="اختار الباقة"
              options={
                fetchedPackages?.length > 0 &&
                fetchedPackages.map((obj) => ({
                  title: obj?.name,
                  value: obj?.id
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="client-form"
            />
          </div>
        </div>

        <div className="modal-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            onClick={() => {
              dispatch(setPostFormModalOpened(false));
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {/* {isLoadingSignin && <LoadingModal />} */}
    </Form>
  );
};

export default ClientForm;
