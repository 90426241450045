import React from 'react';

const VideoIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.99998 15.1667H5.99998C2.37998 15.1667 0.833313 13.62 0.833313 10V6.00004C0.833313 2.38004 2.37998 0.833374 5.99998 0.833374H9.99998C13.62 0.833374 15.1666 2.38004 15.1666 6.00004V10C15.1666 13.62 13.62 15.1667 9.99998 15.1667ZM5.99998 1.83337C2.92665 1.83337 1.83331 2.92671 1.83331 6.00004V10C1.83331 13.0734 2.92665 14.1667 5.99998 14.1667H9.99998C13.0733 14.1667 14.1666 13.0734 14.1666 10V6.00004C14.1666 2.92671 13.0733 1.83337 9.99998 1.83337H5.99998Z"
        fill={color || '#fff'}
      />
      <path
        d="M14.32 5.23999H1.67999C1.40666 5.23999 1.17999 5.01332 1.17999 4.73999C1.17999 4.46666 1.39999 4.23999 1.67999 4.23999H14.32C14.5933 4.23999 14.82 4.46666 14.82 4.73999C14.82 5.01332 14.6 5.23999 14.32 5.23999Z"
        fill={color || '#fff'}
      />
      <path
        d="M5.67999 5.14662C5.40666 5.14662 5.17999 4.91995 5.17999 4.64662V1.40662C5.17999 1.13328 5.40666 0.906616 5.67999 0.906616C5.95333 0.906616 6.17999 1.13328 6.17999 1.40662V4.64662C6.17999 4.91995 5.95333 5.14662 5.67999 5.14662Z"
        fill={color || '#fff'}
      />
      <path
        d="M10.32 4.84662C10.0467 4.84662 9.82001 4.61995 9.82001 4.34662V1.40662C9.82001 1.13328 10.0467 0.906616 10.32 0.906616C10.5933 0.906616 10.82 1.13328 10.82 1.40662V4.34662C10.82 4.62662 10.6 4.84662 10.32 4.84662Z"
        fill={color || '#fff'}
      />
      <path
        d="M7.39333 12.08C7.15333 12.08 6.92667 12.02 6.72 11.9066C6.26667 11.64 6 11.1066 6 10.4333V8.8333C6 8.15996 6.26667 7.61996 6.72667 7.3533C7.18667 7.08663 7.78667 7.12663 8.36667 7.46663L9.75333 8.26663C10.3333 8.59996 10.6733 9.09996 10.6733 9.6333C10.6733 10.1666 10.3333 10.6666 9.74667 11L8.36 11.8C8.04 11.9866 7.70667 12.08 7.39333 12.08ZM7.4 8.17996C7.33333 8.17996 7.27333 8.1933 7.22667 8.21996C7.08667 8.29996 7 8.52663 7 8.8333V10.4333C7 10.7333 7.08667 10.96 7.22667 11.0466C7.36667 11.1266 7.60667 11.0866 7.86667 10.9333L9.25333 10.1333C9.51333 9.97996 9.67333 9.7933 9.67333 9.6333C9.67333 9.4733 9.52 9.28663 9.25333 9.1333L7.86667 8.3333C7.69333 8.2333 7.52667 8.17996 7.4 8.17996Z"
        fill={color || '#fff'}
      />
    </svg>
  );
};

export default VideoIcon;
