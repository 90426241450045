import React from 'react';

const FacebookIcon = ({ color, width, height, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '104'}
      height={height || '104'}
      viewBox="0 0 104 104"
      fill="none"
    >
      <path
        d="M63.9167 98.5833H44.4167V67.1667H40.2999C37.7866 67.1667 35.75 65.1301 35.75 62.6167V54.3833C35.75 51.8699 37.7866 49.8333 40.2999 49.8333H44.4167V39C44.4167 30.03 51.6967 22.75 60.6667 22.75H72.3667C74.8801 22.75 76.9167 24.7866 76.9167 27.2999V37.7001C76.9167 40.2134 74.8801 42.25 72.3667 42.25H63.9167V49.8333H72.0634C73.45 49.8333 74.7067 50.44 75.5733 51.48C76.44 52.52 76.7867 53.9067 76.5267 55.25L74.88 63.4833C74.4467 65.6066 72.5833 67.1233 70.4167 67.1233H63.9167V98.5833ZM50.9167 92.0833H57.4167V60.6667H68.8567L69.7233 56.3333H57.46V40.2999C57.46 37.7866 59.4967 35.75 62.01 35.75H70.46V29.25H60.6667C55.2933 29.25 50.9167 33.6267 50.9167 39V56.3333H42.25V60.6667H50.9167V92.0833Z"
        fill={color || '#2A9D7C'}
      />
      <path
        d="M65 98.5832H39C15.47 98.5832 5.41666 88.5298 5.41666 64.9998V38.9998C5.41666 15.4698 15.47 5.4165 39 5.4165H65C88.53 5.4165 98.5833 15.4698 98.5833 38.9998V64.9998C98.5833 88.5298 88.53 98.5832 65 98.5832ZM39 11.9165C19.0233 11.9165 11.9167 19.0232 11.9167 38.9998V64.9998C11.9167 84.9765 19.0233 92.0832 39 92.0832H65C84.9767 92.0832 92.0833 84.9765 92.0833 64.9998V38.9998C92.0833 19.0232 84.9767 11.9165 65 11.9165H39Z"
        fill={color || '#2A9D7C'}
      />
    </svg>
  );
};

export default FacebookIcon;
