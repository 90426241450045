import React from 'react';

const CheckmarkCircledIcon = ({ color, width, height, ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99998 15.1668C4.04665 15.1668 0.833313 11.9535 0.833313 8.00016C0.833313 4.04683 4.04665 0.833496 7.99998 0.833496C11.9533 0.833496 15.1666 4.04683 15.1666 8.00016C15.1666 11.9535 11.9533 15.1668 7.99998 15.1668ZM7.99998 1.8335C4.59998 1.8335 1.83331 4.60016 1.83331 8.00016C1.83331 11.4002 4.59998 14.1668 7.99998 14.1668C11.4 14.1668 14.1666 11.4002 14.1666 8.00016C14.1666 4.60016 11.4 1.8335 7.99998 1.8335Z"
        fill={color || '#fff'}
      />
      <path
        d="M7.05334 10.3867C6.92 10.3867 6.79334 10.3334 6.7 10.2401L4.81333 8.3534C4.62 8.16007 4.62 7.84007 4.81333 7.64673C5.00667 7.4534 5.32667 7.4534 5.52 7.64673L7.05334 9.18007L10.48 5.7534C10.6733 5.56007 10.9933 5.56007 11.1867 5.7534C11.38 5.94673 11.38 6.26673 11.1867 6.46006L7.40667 10.2401C7.31334 10.3334 7.18667 10.3867 7.05334 10.3867Z"
        fill={color || '#fff'}
      />
    </svg>
  );
};

export default CheckmarkCircledIcon;
