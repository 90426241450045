/* eslint-disable eqeqeq */
import accountTypes from './accountTypes';
import merchentRouterLinks from '../components/app/merchent-routes/merchentRouterLinks';
import userRouterLinks from '../components/app/user-routes/uesRouterLinks';

// isOrder
// 1=>new
// 3=>canceld by user
// 4=>accepted
// 5=>rejected
// 6=>completed

const baseLabelTransString = (user, t, key) => {
  if (user?.accountType == accountTypes()?.user) {
    return t(`userOrderStatus.${key}`);
  } else if (
    user?.accountType == accountTypes()?.merchent ||
    user?.accountType == accountTypes()?.employee
  )
    return t(`merchentorderStatus.${key}`);
};

const getUrl = (user, urlOptions) => {
  if (user?.accountType == accountTypes()?.user) {
    return `${userRouterLinks?.myOrders}?status=${
      urlOptions?.status || ''
    }&page=1`;
  } else if (
    user?.accountType == accountTypes()?.merchent ||
    user?.accountType == accountTypes()?.employee
  )
    return `${merchentRouterLinks?.storeOrders(urlOptions?.storeId)}?status=${
      urlOptions?.status || ''
    }&page=1`;
};

export const orderStatusValues = {
  all: '',
  new: 1,
  canceledByUser: 3,
  accepted: 4,
  rejectedByStore: 5,
  completed: 6
};
export const orderStatusArr = (user, t, arrOptions) => {
  return [
    {
      name: baseLabelTransString(user, t, 'all'),
      status: orderStatusValues?.all,
      objKey: 'all',
      id: 'all_orders',
      url: getUrl(user, {
        status: orderStatusValues?.all,
        storeId: arrOptions?.storeId || ''
      })
    },
    {
      name: baseLabelTransString(user, t, 'new'),
      objKey: 'new',
      status: orderStatusValues?.new,
      id: orderStatusValues?.new,
      url: getUrl(user, {
        status: orderStatusValues?.new,
        storeId: arrOptions?.storeId || ''
      })
    },
    {
      name: baseLabelTransString(user, t, 'canceled'),
      objKey: 'canceledByUser',
      status: orderStatusValues?.canceledByUser,
      id: orderStatusValues?.canceledByUser,
      url: getUrl(user, {
        status: orderStatusValues?.canceledByUser,
        storeId: arrOptions?.storeId || ''
      })
    },
    {
      name: baseLabelTransString(user, t, 'accepted'),
      objKey: 'accepted',
      status: orderStatusValues?.accepted,
      id: orderStatusValues?.accepted,
      url: getUrl(user, {
        status: orderStatusValues?.accepted,
        storeId: arrOptions?.storeId || ''
      })
    },
    {
      name: baseLabelTransString(user, t, 'rejected'),
      objKey: 'rejectedByStore',
      status: orderStatusValues?.rejectedByStore,
      id: orderStatusValues?.rejectedByStore,
      url: getUrl(user, {
        status: orderStatusValues?.rejectedByStore,
        storeId: arrOptions?.storeId || ''
      })
    },
    {
      name: baseLabelTransString(user, t, 'completed'),
      objKey: 'completed',
      status: orderStatusValues?.completed,
      id: orderStatusValues?.completed,
      url: getUrl(user, {
        status: orderStatusValues?.completed,
        storeId: arrOptions?.storeId || ''
      })
    }
  ];
};
