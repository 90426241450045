import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCojlbJ_JfGoTbi8Ndom93TcwmdKfQ_z8U',
  authDomain: 'second-drake-267420.firebaseapp.com',
  projectId: 'second-drake-267420',
  storageBucket: 'second-drake-267420.appspot.com',
  messagingSenderId: '995343988120',
  appId: '1:995343988120:web:f93916e00c97bcf9d867ed'
};

firebase.initializeApp(firebaseConfig);
let messaging = null;
if (firebase?.messaging.isSupported()) {
  messaging = firebase.messaging();
}

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getCurrentToken = async (setIsTokenFound) => {
  let currentToken = '';
  if (messaging) {
    try {
      currentToken = await messaging.getToken({ vapidKey: publicKey });
      if (currentToken) {
        setIsTokenFound(true);
        console.log('current token : ', currentToken);
      } else {
        setIsTokenFound(false);
      }
    } catch (error) {
      console.log('An error occurred while retrieving token. ', error);
    }
  }

  return currentToken;
};

// foreground FCM
export const onMessageListener = () => {
  if (messaging) {
    return new Promise((resolve) => {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    });
  }
};
