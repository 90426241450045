import './CustomEmpty.scss';
const CustomEmpty = ({ style, ...props }) => {
  return (
    <div style={style} {...props} className="custom-empty-comp">
      <img src="/assets/imgs/logo/logo-empty.svg" alt="empty" />
      {props.children || null}
    </div>
  );
};

export default CustomEmpty;
