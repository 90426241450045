/* eslint-disable react-hooks/exhaustive-deps */
import AppPackagesFilter from './AppPackagesPageFilter';
import './AppPackagesPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import sleep from '../../utils/sleep';
import { LoadingOutlined } from '@ant-design/icons';
import {
  setConfirmModal,
  setSelectedTab,
  deviceOrBrand,
  setDeleteModalOpened
} from '../../redux/packages-slice/appPackagesSlice';
import AppPackagesPageTable from './AppPackagesPageTable';
import ConfirmModal from '../../common/confirm-modal/ConfirmModal';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes';
import DevicesTable from './DevicesTable';
import PlusIcon from '../../common/icons/PlusIcon';
import {
  setDeleteModalOpened as setDeleteDeviceModalOpened,
  setDeviceFormOpenedAction,
  setIsLoadingDevices
} from '../../redux/devices-slice/devicesSlice';
import { AnimatePresence, motion } from 'framer-motion';
import Xicon from '../../common/icons/Xicon';
import DeviceForm from './DeviceForm';
import BrandsTable from './BrandsTable';
import {
  deleteDeviceAction,
  getDevicesAction
} from '../../redux/devices-slice/devicesSliceThunkApis';
import {
  setBrandFormOpenedAction,
  setIsLoadingBrands,
  setDeleteModalOpened as setDeleteBrandModalOpened
} from '../../redux/brands-slice/brandsSlice';
import {
  deleteBrandAction,
  getBrandsAction
} from '../../redux/brands-slice/brandsSliceThunkApis';
import BrandModal from './BrandModal';
import {
  deleteAppPackageAction,
  getPackagesAction,
  toggleAppPackageAction
} from '../../redux/packages-slice/appPackagesSliceThunkApi';
import PackageForm from './PackageForm';
import DeviceDescription from './DeviceDescription';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const AppPackagesPage = () => {
  const dispatch = useDispatch();
  const opened = useSelector((store) => store.appPackages.confirmModal.opened);
  const deleteModalOpened = useSelector(
    (store) => store.appPackages.deleteModalOpened
  );
  const type = useSelector((store) => store.appPackages.confirmModal.type);
  const isLoadingTableData = useSelector(
    (store) => store.appPackages.isLoadingTableData
  );
  const selectedTableRow = useSelector(
    (store) => store.appPackages.selectedTableRow
  );
  const packageFormOpened = useSelector(
    (store) => store.appPackages.packageFormOpened
  );
  const watchPackageDevice = useSelector(
    (store) => store.appPackages.watchPackageDevice
  );
  const selectedBrandRow = useSelector(
    (store) => store.brands.selectedTableRow
  );
  const selectedDeviceRow = useSelector(
    (store) => store.devices.selectedTableRow
  );
  const fetchDataCount = useSelector(
    (store) => store.appPackages.fetchDataCount
  );
  const fetchBrandsCount = useSelector((store) => store.brands.fetchDataCount);
  const fetchDevicesCount = useSelector(
    (store) => store.devices.fetchDataCount
  );
  const selectedTab = useSelector((store) => store.appPackages.selectedTab);
  const isSubmitting = useSelector((store) => store.appPackages.isSubmitting);
  const isSubmittingBrands = useSelector((store) => store.brands.isSubmitting);
  const isSubmittingDevices = useSelector(
    (store) => store.devices.isSubmitting
  );
  const deviceFormOpened = useSelector(
    (store) => store.devices.deviceFormOpened
  );
  const deleteBrandModalOpened = useSelector(
    (store) => store.brands.deleteModalOpened
  );
  const deleteDeviceModalOpened = useSelector(
    (store) => store.devices.deleteModalOpened
  );
  const fetchedDevices = useSelector((store) => store.devices.fetchedDevices);
  const fetchedBrands = useSelector((store) => store.brands.fetchedBrands);
  const brandFormOpened = useSelector((store) => store.brands.brandFormOpened);
  const { search } = useLocation();
  const values = queryString.parse(search);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let isMounted = true;
  useEffect(() => {
    if (isMounted)
      dispatch(
        getPackagesAction({
          filterObj: values
        })
      );

    return function () {
      isMounted = false;
    };
  }, [fetchDataCount, search]);
  useEffect(() => {
    if (isMounted) dispatch(getBrandsAction());
    return function () {
      isMounted = false;
    };
  }, [fetchBrandsCount]);
  useEffect(() => {
    if (isMounted) dispatch(getDevicesAction());
    return function () {
      isMounted = false;
    };
  }, [fetchDevicesCount]);

  const handleTogglePackage = () => {
    if (isMounted && selectedTableRow?.id) {
      const formData = new FormData();
      formData.append('package_id', selectedTableRow.id);
      dispatch(
        toggleAppPackageAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };
  const handleDeletePackage = () => {
    if (isMounted && selectedTableRow?.id) {
      const formData = new FormData();
      formData.append('package_id', selectedTableRow.id);
      dispatch(
        deleteAppPackageAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };
  const handleDeleteBrand = () => {
    if (isMounted && selectedBrandRow?.id) {
      const formData = new FormData();
      formData.append('brand_id', selectedBrandRow.id);
      dispatch(
        deleteBrandAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };
  const handleDeleteDevice = () => {
    if (isMounted && selectedDeviceRow?.id) {
      const formData = new FormData();
      formData.append('device_id', selectedDeviceRow.id);
      dispatch(
        deleteDeviceAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  if (isLoadingTableData) {
    return (
      <div className="app-packages-page">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 360
          }}
          className="loading-wrapper"
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      </div>
    );
  }
  return (
    <div className="app-packages-page">
      <div className="page-content-devices-wrapper">
        <div>
          <AnimatePresence>
            {!deviceFormOpened && !packageFormOpened && (
              <motion.div
                // layout
                initial={{
                  width: 0,
                  height: 0,
                  opacity: 0,
                  translateX: 700,
                  transition: {
                    opacity: { ease: 'linear', duration: 0.3 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                animate={{
                  width: '100%',
                  height: 'auto',
                  opacity: 1,
                  translateX: 0,
                  transition: {
                    delay: 0.7,
                    opacity: { ease: 'linear', duration: 0.8 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                exit={{
                  width: 400,
                  height: 0,
                  opacity: 0,
                  translateX: 400,
                  transition: {
                    opacity: { ease: 'linear', duration: 0.5 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                className="page-main-content"
              >
                <div className="page-title">الباقات والاشتراكات</div>
                <AppPackagesFilter />
                <AppPackagesPageTable />
              </motion.div>
            )}
          </AnimatePresence>

          {/* PACKAGE FORM */}
          <AnimatePresence>
            {packageFormOpened && (
              <motion.div
                initial={{
                  width: 0,
                  height: 0,
                  opacity: 0,
                  translateX: 700
                }}
                animate={{
                  width: '100%',
                  height: 'auto',
                  opacity: 1,
                  translateX: 0,
                  transition: {
                    delay: 0.6,
                    opacity: { ease: 'linear', duration: 0.5 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                exit={{
                  width: 200,
                  height: 0,
                  opacity: 0,
                  translateX: 400,
                  transition: {
                    opacity: { ease: 'linear', duration: 0.5 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                // transition={{
                //   delay: 0.7,
                //   opacity: { ease: 'linear', duration: 0.5 },
                //   width: { ease: 'linear', duration: 0.5 },
                //   translateX: { ease: 'linear', duration: 0.5 }
                // }}
                className="device-form-wrapper"
              >
                <PackageForm />
              </motion.div>
            )}
          </AnimatePresence>
          {/* End package form */}

          {/* DEVICES FORM */}
          <AnimatePresence>
            {deviceFormOpened && (
              <motion.div
                initial={{
                  width: 0,
                  height: 0,
                  opacity: 0,
                  translateX: 700
                }}
                animate={{
                  width: '100%',
                  height: 'auto',
                  opacity: 1,
                  translateX: 0,
                  transition: {
                    delay: 0.6,
                    opacity: { ease: 'linear', duration: 0.5 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                exit={{
                  width: 200,
                  height: 0,
                  opacity: 0,
                  translateX: 400,
                  transition: {
                    opacity: { ease: 'linear', duration: 0.5 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                // transition={{
                //   delay: 0.7,
                //   opacity: { ease: 'linear', duration: 0.5 },
                //   width: { ease: 'linear', duration: 0.5 },
                //   translateX: { ease: 'linear', duration: 0.5 }
                // }}
                className="device-form-wrapper"
              >
                <DeviceForm />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* PAGE SIDE CONTENT */}

        {/* DEVICES LIST */}
        <div className="devices-add-device-form-device-desc-wrapper">
          <AnimatePresence>
            {packageFormOpened && (
              <motion.div
                initial={{
                  width: 0,
                  height: 0,
                  opacity: 0,
                  translateX: '-700px',
                  transition: {
                    opacity: { ease: 'linear', duration: 0.3 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                animate={{
                  width: '100%',
                  height: 'auto',
                  opacity: 1,
                  translateX: 0,
                  transition: {
                    delay: 0.7,
                    opacity: { ease: 'linear', duration: 0.8 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                exit={{
                  width: 400,
                  height: 0,
                  opacity: 0,
                  translateX: '-400px',
                  transition: {
                    opacity: { ease: 'linear', duration: 0.5 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
              >
                <DeviceDescription device={watchPackageDevice} />
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {!packageFormOpened && (
              <motion.div
                initial={{
                  width: 0,
                  height: 0,
                  opacity: 0,
                  translateX: -700,
                  transition: {
                    opacity: { ease: 'linear', duration: 0.3 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                animate={{
                  width: '100%',
                  height: 'auto',
                  opacity: 1,
                  translateX: 0,
                  transition: {
                    delay: 0.7,
                    opacity: { ease: 'linear', duration: 0.8 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                exit={{
                  width: 400,
                  height: 0,
                  opacity: 0,
                  translateX: -400,
                  transition: {
                    opacity: { ease: 'linear', duration: 0.5 },
                    width: { ease: 'linear', duration: 0.5 },
                    translateX: { ease: 'linear', duration: 0.5 }
                  }
                }}
                className="page-devices-wrapper"
              >
                <p className="section-title">
                  <button
                    className={`${
                      selectedTab === deviceOrBrand?.device ? 'active' : ''
                    }`}
                    onClick={async () => {
                      dispatch(setSelectedTab(deviceOrBrand?.device));
                      dispatch(setIsLoadingDevices(true));
                      await sleep(300);
                      dispatch(setIsLoadingDevices(false));
                    }}
                  >
                    الأجهزة المستخدمة
                  </button>
                  <button
                    className={`${
                      selectedTab === deviceOrBrand?.brand ? 'active' : ''
                    }`}
                    onClick={async () => {
                      dispatch(setSelectedTab(deviceOrBrand?.brand));
                      dispatch(setIsLoadingBrands(true));
                      await sleep(300);
                      dispatch(setIsLoadingBrands(false));
                    }}
                  >
                    براندات الاجهزة
                  </button>
                </p>
                {selectedTab === deviceOrBrand?.device ? (
                  <>
                    <DevicesTable fetchedDevices={fetchedDevices} />
                    <motion.button
                      type="button"
                      onClick={() =>
                        dispatch(setDeviceFormOpenedAction(!deviceFormOpened))
                      }
                      animate={{
                        backgroundColor: deviceFormOpened ? 'red' : '#2a9d7c',
                        rotate: deviceFormOpened ? '-180deg' : 0,
                        transition: {
                          backgroundColor: { ease: 'easeOut', duration: 0.3 },
                          rotate: { ease: 'easeOut', duration: 0.3 }
                        }
                      }}
                      className={`add-device-btn ${
                        deviceFormOpened ? 'with-device-form' : ''
                      }`}
                    >
                      {deviceFormOpened ? (
                        <Xicon color="#fff" />
                      ) : (
                        <PlusIcon color="#fff" />
                      )}
                    </motion.button>
                  </>
                ) : (
                  <>
                    <BrandsTable fetchedBrands={fetchedBrands} />
                    <button
                      type="button"
                      onClick={() =>
                        dispatch(setBrandFormOpenedAction(!brandFormOpened))
                      }
                      className="add-brand-btn"
                    >
                      <PlusIcon color="#fff" />
                    </button>
                  </>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* END PAGE SIDE CONTENT */}
      </div>

      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}

      <BrandModal
        modalOpened={brandFormOpened}
        setModalOpened={dispatch(setBrandFormOpenedAction)}
      />

      {/* delete package modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteModalOpened}
        modalType={confirmModalTypesValues?.errorModal}
        setModalOpened={(v) => dispatch(setDeleteModalOpened(v))}
        handleAccept={handleDeletePackage}
      >
        <h3>{`هل انت متأكد من حذف الباقة ${selectedTableRow?.name || ''}`}</h3>
      </ConfirmModal>
      {/* end delete package modal */}

      {/* confirm package modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={opened}
        modalType={type}
        setModalOpened={(v) =>
          dispatch(
            setConfirmModal({
              opened: v,
              type: ''
            })
          )
        }
        handleAccept={handleTogglePackage}
      >
        {type === confirmModalTypesValues?.successModal && (
          <h3>{`هل انت متأكد من تفعيل الباقة ${
            selectedTableRow?.name || ''
          }`}</h3>
        )}
        {type === confirmModalTypesValues?.errorModal && (
          <h3>{`هل انت متأكد من ايقاف عمل الباقة ${
            selectedTableRow?.name || ''
          }`}</h3>
        )}
      </ConfirmModal>
      {/* end confirm package modal */}

      {/* delete brand modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteBrandModalOpened}
        modalType={confirmModalTypesValues?.errorModal}
        setModalOpened={(v) => dispatch(setDeleteBrandModalOpened(v))}
        handleAccept={handleDeleteBrand}
      >
        <h3>{`هل أنت متأكد من حذف البراند ${selectedBrandRow?.name || ''}`}</h3>
      </ConfirmModal>
      {/* ende delete brand modal */}

      {/* delete device modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteDeviceModalOpened}
        modalType={confirmModalTypesValues?.errorModal}
        setModalOpened={(v) => dispatch(setDeleteDeviceModalOpened(v))}
        handleAccept={handleDeleteDevice}
      >
        <h3>{`هل أنت متأكد من حذف الجهاز ${selectedDeviceRow?.name || ''}`}</h3>
      </ConfirmModal>
      {/* ende delete device modal */}

      {(isSubmitting || isSubmittingBrands || isSubmittingDevices) && (
        <LoadingModal />
      )}
    </div>
  );
};

export default AppPackagesPage;
