import deviceDescIcon from '../assets/imgs/icons/form-icons/device-desc-icon.svg';
import deviceIcon from '../assets/imgs/icons/form-icons/device-icon.svg';
import fontSizeIcon from '../assets/imgs/icons/form-icons/font-size-icon.svg';
import imageIcon from '../assets/imgs/icons/form-icons/image-icon.svg';
import packageIcon from '../assets/imgs/icons/form-icons/package-icon.svg';
import paperSizeIcon from '../assets/imgs/icons/form-icons/paper-size-icon.svg';
import priceIcon from '../assets/imgs/icons/form-icons/price-icon.svg';
import specsIcon from '../assets/imgs/icons/form-icons/specs-icon.svg';
import medalIcon from '../assets/imgs/icons/form-icons/medal-icon.svg';
import emailIcon from '../assets/imgs/icons/form-icons/email-icon.svg';
import phoneIcon from '../assets/imgs/icons/form-icons/phone-icon.svg';
import lockIcon from '../assets/imgs/icons/form-icons/lock-icon.svg';

const imagesSrcs = {
  deviceDescIcon,
  deviceIcon,
  fontSizeIcon,
  imageIcon,
  packageIcon,
  paperSizeIcon,
  priceIcon,
  specsIcon,
  medalIcon,
  emailIcon,
  phoneIcon,
  lockIcon
};

export default imagesSrcs;
