/* eslint-disable eqeqeq */

import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import EditIcon from '../../common/icons/EditIcon';
import ReceiptPrintIcon from '../../common/icons/ReceiptPrintIcon';
import TrashIcon from '../../common/icons/TrashIcon';

const RecentClientsTableColumns = (
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  i18n,
  t,
  props
) => {
  // const handleViewDelegateDetails = (record) => {
  //   const found = allFetchedTableData.find((obj) => obj.id === record.id);
  //   if (found) {
  //     setSelectedTableRow({ ...found });
  //     setDetailsModalOpened(true);
  //   }
  // };
  const handleEdit = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setFormModalOpened(true);
    }
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap two-rows">
        <div className="main-btns-wrapper">
          <button
            className="action-btn edit"
            onClick={() => handleEdit(record)}
          >
            <TrashIcon color="#196c10" />
          </button>
          <button
            className="action-btn edit"
            onClick={() => handleEdit(record)}
          >
            <EditIcon color="#196c10" />
          </button>
          <button
            className="action-btn edit"
            onClick={() => handleEdit(record)}
          >
            <ReceiptPrintIcon color="#196c10" />
          </button>
        </div>

        <div className="accept-reject-btns">
          <button className="accept-btn">
            <CheckmarkCircledIcon />
            قبول
          </button>
          <button className="reject-btn">
            <CancelCircledIcon />
            رفض
          </button>
        </div>
      </div>
    );
  };

  // const SingleTag = (item) => {
  //   if (item?.pacakge?.name) {
  //     return ()
  //   }
  // }

  // const renderSubscriptionTags = arr => {
  //   return (
  //     arr.map(item => {
  //       return (
  //         <SingleTag key={item?.id} item={item} />
  //       )
  //     })
  //   )
  // }

  return [
    {
      title: 'الإســـم',
      dataIndex: 'name',
      render: (_, record) => {
        return record?.name || '----';
      }
    },
    {
      title: 'رقم الهاتف',
      dataIndex: 'phone',
      render: (_, record) => {
        return record?.phone || '----';
      }
    },
    {
      title: 'الإشــتراك',
      dataIndex: 'subscription',
      render: (_, record) => {
        return '----';
        // return record?.tags?.length > 0
        //   ? renderSubscriptionTags(record?.tags)
        //   : '---';
      }
    },

    {
      title: 'إجراءات',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }

    // {
    //   title: 'عرض التفاصيل',
    //   dataIndex: 'details',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <Tooltip title="عرض التفاصيل">
    //         <Button
    //           size="large"
    //           type="dashed"
    //           shape="round"
    //           icon={<EyeOutlined />}
    //           onClick={() => handleViewDelegateDetails(record)}
    //         />
    //       </Tooltip>
    //     );
    //   }
    // }
  ];
};

export default RecentClientsTableColumns;
