/* eslint-disable eqeqeq */

import CustomImage from '../../common/custom-image/CustomImage';
import EditIcon from '../../common/icons/EditIcon';
import TrashIcon from '../../common/icons/TrashIcon';
import medalIcon from '../../assets/imgs/icons/packages-icons/medal.svg';
import posIcon from '../../assets/imgs/icons/packages-icons/pos-icon.svg';
import CommonTag from '../../common/common-tag/CommonTag';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import { subscriptionStatus } from '../../const-values/subscriptionStatus';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes';

const ClientsTableColumns = ({
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  setConfirmModal,
  setDeleteModalOpened,
  i18n,
  t,
  ...props
}) => {
  const handleEdit = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setFormModalOpened(true);
    }
  };
  const handleDeleteClient = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setDeleteModalOpened(true);
    }
  };

  const handleClickConfirm = (record, type) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setConfirmModal({
        opened: true,
        type
      });
    }
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap two-rows">
        <div className="main-btns-wrapper">
          <button
            className="action-btn edit"
            onClick={() => handleDeleteClient(record)}
          >
            <TrashIcon color="#196c10" />
          </button>
          <button
            className="action-btn edit"
            onClick={() => handleEdit(record)}
          >
            <EditIcon color="#196c10" />
          </button>
        </div>

        {record?.subscriptionStatus == subscriptionStatus?.active && (
          <div className="accept-reject-btns">
            <button
              onClick={() => {
                handleClickConfirm(
                  record,
                  confirmModalTypesValues?.successModal
                );
              }}
              className="accept-btn"
            >
              <CheckmarkCircledIcon />
              قبول
            </button>
            <button
              onClick={() => {
                handleClickConfirm(record, confirmModalTypesValues?.errorModal);
              }}
              className="reject-btn"
            >
              <CancelCircledIcon />
              رفض
            </button>
          </div>
        )}
      </div>
    );
  };

  // const SingleTag = (item) => {
  //   if (item?.pacakge?.name) {
  //     return ()
  //   }
  // }

  // const renderSubscriptionTags = arr => {
  //   return (
  //     arr.map(item => {
  //       return (
  //         <SingleTag key={item?.id} item={item} />
  //       )
  //     })
  //   )
  // }

  const RenderTag = ({ iconSrc, title, className }) => {
    return <CommonTag title={title} iconSrc={iconSrc} className={className} />;
  };

  return [
    {
      title: '',
      dataIndex: 'image',
      render: (_, record) => {
        return (
          <div className="table-img-wrapper">
            <CustomImage className="table-package-img" src={record?.image} />
          </div>
        );
      }
    },
    {
      title: 'الاسم',
      dataIndex: 'name',
      render: (_, record) => {
        return record?.name || '----';
      }
    },
    {
      title: 'رقم الهاتف',
      dataIndex: 'phone',
      render: (_, record) => {
        return record?.phone || '----';
      }
    },
    {
      title: 'الاشتراك',
      dataIndex: 'tags',
      render: (_, record) => {
        return (
          <div className="package-tags">
            <div className="subscription-tags">
              {record?.subscription?.package?.duration ? (
                <RenderTag
                  iconSrc={medalIcon}
                  title={`اشتراك ${
                    record?.subscription?.package?.duration || ''
                  }`}
                  className="yellow"
                />
              ) : null}
              <RenderTag
                iconSrc={medalIcon}
                title={`باقة ${
                  record?.subscription?.package?.price
                    ? `${record?.subscription?.package?.price} ${t(
                        'currency.sar'
                      )}`
                    : ''
                }`}
                className="yellow"
              />
            </div>
            <div className="device-tags">
              {record?.subscription?.package?.device?.name ? (
                <RenderTag
                  iconSrc={posIcon}
                  title={`${record?.subscription?.package?.device?.name} ${record?.subscription?.package?.device?.model}`}
                  className="green"
                />
              ) : null}
              {record?.subscription?.package?.device?.brand?.name ? (
                <RenderTag
                  iconSrc={posIcon}
                  title={`${record?.subscription?.package?.device.brand.name}`}
                  className="green"
                />
              ) : null}
            </div>
          </div>
        );
      }
    },

    {
      title: 'إجراءات',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }

    // {
    //   title: 'عرض التفاصيل',
    //   dataIndex: 'details',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <Tooltip title="عرض التفاصيل">
    //         <Button
    //           size="large"
    //           type="dashed"
    //           shape="round"
    //           icon={<EyeOutlined />}
    //           onClick={() => handleViewDelegateDetails(record)}
    //         />
    //       </Tooltip>
    //     );
    //   }
    // }
  ];
};

export default ClientsTableColumns;
