/* eslint-disable eqeqeq */
import accountTypes from '../const-values/accountTypes';
import notificationTypes from '../const-values/notificationTypes';
import { orderStatusValues } from '../const-values/orderStatus';
import { notification } from 'antd';
import merchentRouterLinks from '../components/app/merchent-routes/merchentRouterLinks';
import userRouterLinks from '../components/app/user-routes/uesRouterLinks';

const handleClickNotification = ({
  obj,
  user,
  navigate,
  setFetchCount,
  readNotification
}) => {
  // user, and employee fcm links

  if (obj?.isRead === 0) {
    readNotification({
      values: {
        id: obj?.id
      },
      successCb: () => setFetchCount((prev) => prev + 1)
    });
  }
  const type = obj?.type;
  if (user?.accountType === accountTypes()?.user) {
    if (type == notificationTypes()?.newOrder) {
      navigate(
        `${userRouterLinks?.myOrders}?status=${orderStatusValues?.new}&page=1`
      );
    } else if (type == notificationTypes()?.canceledByUser) {
      navigate(
        `${userRouterLinks?.myOrders}?status=${orderStatusValues?.canceledByUser}&page=1`
      );
    } else if (type == notificationTypes()?.storeAcceptedOrder) {
      navigate(
        `${userRouterLinks?.myOrders}?status=${orderStatusValues?.accepted}&page=1`
      );
    } else if (type == notificationTypes()?.storeRejectedOrder) {
      navigate(
        `${userRouterLinks?.myOrders}?status=${orderStatusValues?.rejectedByStore}&page=1`
      );
    } else if (type == notificationTypes()?.orderCompleted) {
      navigate(
        `${userRouterLinks?.myOrders}?status=${orderStatusValues?.completed}&page=1`
      );
    }
  }
  ////////////////////////////////
  // merchent fcm links
  ////////////////////////////////
  else if (user?.accountType === accountTypes()?.merchent) {
    console.log('obj : ', obj);
    // navigate(`${merchentRouterLinks?.storeOrders(obj?.store_id)}&status=${obj?.type}`);
    if (type == notificationTypes()?.newOrder) {
      navigate(
        `${merchentRouterLinks?.storeOrders(obj?.store_id)}?status=${
          orderStatusValues?.new
        }&page=1`
      );
    } else if (type == notificationTypes()?.canceledByUser) {
      navigate(
        `${merchentRouterLinks?.storeOrders(obj?.store_id)}?status=${
          orderStatusValues?.canceledByUser
        }&page=1`
      );
    } else if (type == notificationTypes()?.storeAcceptedOrder) {
      navigate(
        `${merchentRouterLinks?.storeOrders(obj?.store_id)}?status=${
          orderStatusValues?.accepted
        }&page=1`
      );
    } else if (type == notificationTypes()?.storeRejectedOrder) {
      navigate(
        `${merchentRouterLinks?.storeOrders(obj?.store_id)}?status=${
          orderStatusValues?.rejectedByStore
        }&page=1`
      );
    } else if (type == notificationTypes()?.orderCompleted) {
      navigate(
        `${merchentRouterLinks?.storeOrders(obj?.store_id)}?status=${
          orderStatusValues?.completed
        }&page=1`
      );
    }
  }
  notification.destroy();
};

export default handleClickNotification;
