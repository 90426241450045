import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import customApiRequest from '../../helpers/customApiRequest';

const initialState = {
  isLoadingJsonData: false,
  jsonData: [],
  /////////////////////
  isLoadingTableData: false,
  fetchedTableData: [
    {
      id: 1,
      name: 'test name',
      phone: '+96559156650',
      tags: {
        package: {
          name: 'اشتراك سنوى',
          price: '999'
        },
        device: {
          brand: 'SUNAMI',
          name: 'SUNMI P1 Android Handheld POS Machine'
        },
        paymentGateway: {
          name: 'PayTabs'
        }
      }
    }
  ],
  fetchDataCount: 0,
  postFormModalOpened: false,
  deleteModalOpened: false,
  isSubmitting: false,
  selectedTableRow: null,

  tablePagination: {
    current_page: 1,
    par_page: 0,
    total: 0
  }
};

const getAllPostsApi = async (langIsoCode) => {
  try {
    const res = await axios({
      method: 'GET',
      url: 'https://jsonplaceholder.typicode.com/posts',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        lang: langIsoCode
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export const getJsonPlaceholderData = createAsyncThunk(
  'jsonPlaceholder/get',
  async (v, thunkAPI) => {
    console.log('v');
    const res = await axios({
      method: 'GET',
      url: 'https://jsonplaceholder.typicode.com/posts'
    });

    return res?.data;
    // customApiRequest(
    //   getAllPostsApi('en'),
    //   (res) => {
    //     console.log('res : ', res);
    //     return res;
    //   },
    //   (error) => {
    //     console.log('error : ', error);
    //     return thunkAPI.rejectWithValue('THERE WAS AN ERROR');
    //   }
    // );
  }
);

const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState,
  reducers: {
    setPostFormModalOpened: (sliceState, action) => {
      sliceState.postFormModalOpened = action.payload;
    },
    setDeleteModalOpened: (sliceState, action) => {
      sliceState.deleteModalOpened = action.payload;
    },
    setSelectedTableRow: (sliceState, action) => {
      sliceState.selectedTableRow = action.payload;
    }
  },
  extraReducers: {
    [getJsonPlaceholderData.pending]: (state) => {
      state.isLoadingJsonData = true;
    },
    [getJsonPlaceholderData.fulfilled]: (state, action) => {
      state.isLoadingJsonData = false;
      state.jsonData = action?.payload;
    },
    [getJsonPlaceholderData.rejected]: (state) => {
      state.isLoadingJsonData = false;
    }
  }
});

const settingsReducer = settingsSlice.reducer;

export const {
  setPostFormModalOpened,
  setDeleteModalOpened,
  setSelectedTableRow
} = settingsSlice.actions;

export default settingsReducer;
