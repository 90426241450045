import { createAsyncThunk } from '@reduxjs/toolkit';
import createAppPackageApi from '../../apis/packages-apis/createAppPackageApi';
import deleteAppPackageApi from '../../apis/packages-apis/deleteAppPackageApi';
import getAllPackagesApiCall from '../../apis/packages-apis/getAllPackagesApi';
import toggleAppPackageApi from '../../apis/packages-apis/toggleAppPackageApi';
import customApiRequest from '../../helpers/customApiRequest';

export const getPackagesAction = createAsyncThunk(
  'appPackages/getPackagesAction',
  async ({ filterObj }, thunkAPI) => {
    // console.log('thunk api : ', thunkAPI);

    return customApiRequest({
      apiCall: await getAllPackagesApiCall(filterObj),
      thunkAPI
    });
  }
);

// create package
export const createAppPackageAction = createAsyncThunk(
  'appPackages/createAppPackageAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createAppPackageApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
//
// create package
export const updateAppPackageAction = createAsyncThunk(
  'appPackages/updateAppPackageAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createAppPackageApi(dataToBeSubmitted, true),
      thunkAPI
    });
  }
);
//
export const toggleAppPackageAction = createAsyncThunk(
  'appPackages/toggleAppPackageAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await toggleAppPackageApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const deleteAppPackageAction = createAsyncThunk(
  'appPackages/deleteAppPackageAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteAppPackageApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
