import CustomImage from '../../common/custom-image/CustomImage';
import NotesIcon from '../../common/icons/NotesIcon';
import SmillesIcon from '../../common/icons/SmillesIcon';
import UsersSettingsIcon from '../../common/icons/UsersSettingsIcon';
import calenderImg from '../../assets/imgs/profile-images/calendar-icon.svg';
import instagramImg from '../../assets/imgs/profile-images/instagram.svg';
import mobileImg from '../../assets/imgs/profile-images/mobile-icon.svg';
import mailImg from '../../assets/imgs/profile-images/mail-icon.svg';
import youtubeImg from '../../assets/imgs/profile-images/youtube-icon.svg';
import snapchatImg from '../../assets/imgs/profile-images/snapchat.svg';

const BoxStat = ({ icon, value, title }) => {
  return (
    <div className="box-stat">
      <div className="icon-wrap">{icon}</div>
      <div className="value-wrap">{value}</div>
      <div className="title-wrap">{title}</div>
    </div>
  );
};

const SingleInfo = ({ img, value }) => {
  return (
    <div className="single-info-box">
      <img src={img} alt="info" />
      <p>{value}</p>
    </div>
  );
};

const ProfileInfoSection = () => {
  return (
    <div className="profile-info-stats-sections">
      <section className="stats-section">
        <CustomImage
          className="profile-img"
          src="/assets/imgs/logo-black.svg"
        />
        <div className="stats-wrapper">
          <div className="name-email-edit-wrapper">
            <p className="name">mycash</p>
            <p className="email">mycash.info@example.com</p>
          </div>

          <div className="stats-boxs">
            <BoxStat icon={<UsersSettingsIcon />} value={1000} title="عميل" />
            <BoxStat icon={<SmillesIcon />} value={400} title="مسوق" />
            <BoxStat icon={<NotesIcon />} value={2000} title="جهاز مسجل" />
          </div>
        </div>
      </section>

      <section className="profile-info-section">
        <h4>بيانات الحساب</h4>
      </section>
    </div>
  );
};

export default ProfileInfoSection;
