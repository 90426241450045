/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link as RouterLink } from 'react-router-dom';
import MainAppBarContext from '../../contexts/main-app-bar-context/MainAppBarProvider';

const MainAppBarSideMenu = ({ visibleSubMenu, menuItems }) => {
  const ref = useRef();
  const { setLinks, setSelectedLink } = useContext(MainAppBarContext);

  // useOnClickOutside(ref, hanldeOutside);

  return (
    menuItems?.length > 0 && (
      <div ref={ref}>
        <CSSTransition
          in={visibleSubMenu}
          timeout={300}
          classNames="sub-menu-transition-group"
          unmountOnExit
          // onEnter={() => setShowButton(false)}
          // onExited={() => setShowButton(true)}
        >
          <ul className="sub-menu-ul">
            {menuItems?.length > 0 &&
              menuItems.map((item, index) => (
                <li
                  key={index}
                  className="sub-menu-li"
                  onClick={(e) => {
                    e.stopPropagation();
                    setLinks((prevState) => {
                      return prevState.map((obj) => ({
                        ...obj,
                        visibleSubMenu: false
                      }));
                    });
                    setSelectedLink(null);
                  }}
                >
                  <RouterLink to={item.to}>{item?.name}</RouterLink>
                </li>
              ))}
          </ul>
        </CSSTransition>
      </div>
    )
  );
};

export default MainAppBarSideMenu;
