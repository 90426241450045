const apisUrls = {
  // app packages
  getAppPackages: (filterObj) =>
    `/Package/get?searchText=${filterObj?.searchText || ''}&status=${
      filterObj?.status || ''
    }&page=${filterObj?.page || 1}`,
  getSingleAppPackage: '/Package/single',
  createAppPackage: '/Package/create',
  updateAppPackage: '/Package/update',
  deleteAppPackage: '/Package/delete',
  toggleAppPackage: '/Package/activeOrUnActive',
  //
  // Devices urls
  getDevices: `/Device/get`,
  getSingleDevice: '/Device/single',
  createDevice: '/Device/create',
  updateDevice: '/Device/update',
  deleteDevice: '/Device/delete',
  //
  // Devices brands urls
  getDevicesBrands: `/DeviceBrand/get`,
  getSingleDeviceBrand: '/DeviceBrand/single',
  createDeviceBrand: '/DeviceBrand/create',
  updateDeviceBrand: '/DeviceBrand/update',
  deleteDeviceBrand: '/DeviceBrand/delete',
  toggleDeviceBrand: '/DeviceBrand/activeOrUnActive',
  //
  // Clients urls
  getClients: (filterObj) =>
    `/Client/get?package_id=${filterObj?.package_id || ''}&searchText=${
      filterObj?.searchText || ''
    }&status=${filterObj?.status || ''}&archive=${
      filterObj?.archive || ''
    }&page=${filterObj?.page || 1}`,
  getSingleClient: '/Client/single',
  createClient: '/Client/create',
  changeClientStatus: '/Client/changeStatus',
  updateClient: '/Client/update',
  deleteClient: '/Client/delete',
  //
  // Influencers urls
  getInfluencers: `/Influencer/get`,
  getSingleInfluencer: '/Influencer/single',
  getInfluencerSocials: '/Influencer/socials',
  createInfluencer: '/Influencer/create',
  updateInfluencer: '/Influencer/update',
  deleteInfluencer: '/Influencer/delete'
  //
};

export default apisUrls;
