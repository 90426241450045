import React from 'react';

const AgentsIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.99998 15.1666H5.99998C2.37998 15.1666 0.833313 13.6199 0.833313 9.99992V5.99992C0.833313 2.37992 2.37998 0.833252 5.99998 0.833252H9.99998C13.62 0.833252 15.1666 2.37992 15.1666 5.99992V9.99992C15.1666 13.6199 13.62 15.1666 9.99998 15.1666ZM5.99998 1.83325C2.92665 1.83325 1.83331 2.92658 1.83331 5.99992V9.99992C1.83331 13.0733 2.92665 14.1666 5.99998 14.1666H9.99998C13.0733 14.1666 14.1666 13.0733 14.1666 9.99992V5.99992C14.1666 2.92658 13.0733 1.83325 9.99998 1.83325H5.99998Z"
        fill={color || '#fff'}
      />
      <path
        d="M5.88 6.50004C5.31333 6.50004 4.74667 6.28671 4.31333 5.85338C4.12 5.66004 4.12 5.34004 4.31333 5.14671C4.50667 4.95338 4.82667 4.95338 5.02 5.14671C5.49334 5.62004 6.26667 5.62004 6.74 5.14671C6.93333 4.95338 7.25333 4.95338 7.44667 5.14671C7.64 5.34004 7.64 5.66004 7.44667 5.85338C7.01334 6.28004 6.44667 6.50004 5.88 6.50004Z"
        fill={color || '#fff'}
      />
      <path
        d="M10.12 6.50004C9.55333 6.50004 8.98666 6.28671 8.55333 5.85338C8.35999 5.66004 8.35999 5.34004 8.55333 5.14671C8.74666 4.95338 9.06666 4.95338 9.25999 5.14671C9.73333 5.62004 10.5067 5.62004 10.98 5.14671C11.1733 4.95338 11.4933 4.95338 11.6867 5.14671C11.88 5.34004 11.88 5.66004 11.6867 5.85338C11.2533 6.28004 10.6867 6.50004 10.12 6.50004Z"
        fill={color || '#fff'}
      />
      <path
        d="M8 12.7667C6.06667 12.7667 4.5 11.1934 4.5 9.26675C4.5 8.66008 4.99333 8.16675 5.6 8.16675H10.4C11.0067 8.16675 11.5 8.66008 11.5 9.26675C11.5 11.1934 9.93333 12.7667 8 12.7667ZM5.6 9.16675C5.54667 9.16675 5.5 9.21341 5.5 9.26675C5.5 10.6467 6.62 11.7667 8 11.7667C9.38 11.7667 10.5 10.6467 10.5 9.26675C10.5 9.21341 10.4533 9.16675 10.4 9.16675H5.6Z"
        fill={color || '#fff'}
      />
    </svg>
  );
};

export default AgentsIcon;
