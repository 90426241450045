import SocialStatistics from './SocialStatistics';
import StatisticsBoxs from './StatisticsBoxs';
import './HomePage.scss';
import RecentClientsTable from './RecentClientsTable';

const HomePage = () => {
  return (
    <div className="home-page">
      <StatisticsBoxs />

      <div className="social-stats-main-box-wrapper">
        <SocialStatistics />
        <div className="main-box">
          <div className="box-title">
            <p>مستخدمى تطبيق</p>
            <p>mycash</p>
          </div>
          <p className="box-value">2</p>
          <p className="value-label">مستخدم</p>
        </div>
      </div>

      {/*  */}
      <RecentClientsTable />
      {/*  */}
    </div>
  );
};

export default HomePage;
